.epm-backdrop,
.epm-modal-container,
.epm-modal {
  z-index: 100;
}

.ember-application {
  --epm-backdrop-background: rgba(153, 152, 150, 0.7);

  /*
   * Fixes for Percy not working properly with just "animation-fill-mode: forwards".
   * This will work fine as long as we do not set a delay for the backdrop out animation.
  */
  .epm-backdrop,
  .epm-modal.epm-fade {
    opacity: 1;
  }

  .epm-backdrop.epm-out,
  .epm-modal.epm-fade.epm-out {
    opacity: 0;
  }
}

.ember-application:not(#ember-testing) {
  /* specified here because we can only have a single out duration for the backdrop */
  --epm-animation-backdrop-out-delay: 0s;
  --epm-animation-backdrop-out-duration: 600ms;

  &.has-popup-modal {
    --epm-animation-backdrop-in-duration: 120ms;
    --epm-animation-modal-in-delay: 0ms;
    --epm-animation-modal-in-duration: 170ms;
    --epm-animation-modal-out-duration: 50ms;
  }

  &.has-fullscreen-modal {
    --epm-animation-backdrop-in-duration: 600ms;
    --epm-animation-modal-in-duration: 250ms;
    --epm-animation-modal-out-duration: 250ms;
  }

  @media (prefers-reduced-motion: reduce) {
    --epm-animation-backdrop-in-duration: 0s !important;
    --epm-animation-backdrop-out-duration: 0s !important;
    --epm-animation-modal-in-duration: 0s !important;
    --epm-animation-modal-out-duration: 0s !important;
    --epm-animation-backdrop-in-delay: 0s !important;
    --epm-animation-backdrop-out-delay: 0s !important;
    --epm-animation-modal-in-delay: 0s !important;
    --epm-animation-modal-out-delay: 0s !important;
  }
}

.epm-modal.epm-fade {
  opacity: 0;
  transform: unset;
  animation: var(--epm-animation-modal-in-duration) step-end var(--epm-animation-modal-in-delay)
    forwards fadein;
}

.epm-modal.epm-fade.epm-out {
  opacity: 1;
  transform: unset;
  animation: var(--epm-animation-modal-out-duration) ease-out var(--epm-animation-modal-out-delay)
    forwards fade-out; // NOTE: the animation name needs to end with "-out" to work correctly
}

.epm-modal.epm-from-bottom {
  opacity: 1;
  transform: translate(0, 0);
  transform-origin: top center;
  animation: from-bottom-in var(--epm-animation-modal-in-duration) ease-out;
}

.epm-modal.epm-from-bottom.epm-out {
  opacity: 0;
  transform: translate(0, calc(50vh + 50%));
  animation: from-bottom-out var(--epm-animation-modal-out-duration) ease-in; // NOTE: the animation name needs to end with "-out" to work correctly
}

@keyframes from-bottom-in {
  0% {
    opacity: 0;
    transform: translate(0, calc(50vh + 50%));
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes from-bottom-out {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }

  100% {
    opacity: 0;
    transform: translate(0, calc(50vh + 50%));
  }
}
