html, body {
  height: 100%;
}

.main-wrapper {
  height: 100%;
  overflow: auto;
  scroll-behavior: smooth;
  background: url("../img/about-bg.jpg") center top no-repeat fixed;
  background-size: cover;
  position: relative;

  &.top-offset {
    display: block;
    // height: calc(100% - 6rem);
    height: 100%;
    padding-top: 6rem;

    .offset-div {
      position: absolute;
      top: 0;
      display: block;
      background-color: #9f9ccc;
      // background-color: #111;
      height: 6rem;
      width: 100%;
      z-index: 10;
    }
  }

  &:before {
    content: '';
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.92);
    z-index: 9;
  }
}

.main-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  z-index: 10;
}

.ember-power-select-options {
  font-size: 15px;
}

.ember-power-select-selected-item {
  width: 100%;

  .selector-data {
    position: absolute;
    right: 53px;
  }
}

.ember-power-select-trigger[aria-disabled=true] {
  background-color: #e9ecef !important;
}

.power-icon {
  font-size: 10px;
}

html.lock-scroll {
  overflow: hidden;
}

.x-toggle-container.small {
  padding: 0 0.25rem 0 0;
}

.x-toggle:checked + label > .x-toggle-light.x-toggle-btn {
  background-color: #2b87db;
}

.ember-power-select-trigger-multiple-input {
  margin-left: 8px;
}

.ember-power-select-selected-item > div {
  width: calc(100% - 8px) !important;
}

.ember-power-select-option span {
  display: block;
}

@media (max-width: 768px) {
  .ember-power-select-option {
    padding: 3px 12px !important;
  }
}

.dropdown-item-sm {
  li.ember-power-select-option {
    font-size: 0.95rem;
    font-weight: 500;
  }
}

.form-control:focus {
  border-color: #623bff !important;
  box-shadow: none !important;
}

.ember-power-select-clear-btn {
  font-size: 1.5rem !important;
  line-height: 1.5 !important;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ember-cli-notifications-notification__container .c-notification__svg {
  vertical-align: middle !important;
}

.ember-cli-notifications-notification__container.ember-cli-notifications-notification__container--top-right {
  margin-top: 6rem;
}

.field-error {
  font-size: 14px;
  line-height: 0.75rem;
  margin: 0;
  margin-top: 3px;
  margin-right: 1px;
  margin-left: 2px;
  padding: 0;
  color: red;
  bottom: -20px;
}

.input-error {
  color: red !important;

  input {
    border-color: red !important;

    &::placeholder {
      color: red !important;
    }
  }
}

.label-sm {
  font-size: 14px;
  margin-left: 4px;
}

.remove-top-space {
  margin-top: 0 !important;
}

.flex-none {
  flex: none;
}

@media (max-width: 768px) {
  .remove-top-space {
    padding: 0 !important;
  }
}

@media (max-width: 768px) {
  .ember-power-select-selected-item,
  .ember-power-select-placeholder {
    margin-left: 16px !important;
    padding: 0.42rem 0;
  }

  .ember-power-select-multiple-options {
    padding: 0.42rem 0 !important;
    padding-left: 8px !important;
  }

  .ember-power-select-status-icon {
    right: 10px !important;
  }

  .ember-power-select-trigger {
    border-radius: 0.7rem !important;
  }

  .ember-power-select-trigger:focus, .ember-power-select-trigger--active {
    border-top: 1px solid #827be0 !important;
    border-bottom: 1px solid #827be0 !important;
    border-right: 1px solid #827be0 !important;
    border-left: 1px solid #827be0 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 111, 233, 0.60) !important;
  }

  .ember-power-select-search-input:focus {
    border-color: #827be0 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 111, 233, 0.60) !important;
  }
}

.scrollable-x {
  overflow-x: auto; /* Enable horizontal scrolling if items overflow */
  white-space: nowrap; /* Keep items in a single line */
  align-items: center; /* Vertically align items */
  -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
  scrollbar-width: none; /* Hide scrollbar in Firefox */

  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar in Chrome, Safari, and Edge */
  }

  .nav-tabs {
    display: inline-flex;
    width: max-content; /* Ensures only the content width is used */
  }
}

.ember-power-select-multiple-options .ember-power-select-multiple-option {
  margin-right: 5px; /* Adjust spacing between items */
}

.ember-power-select-multiple-options .ember-power-select-trigger {
  flex-shrink: 0; /* Prevent shrinking of the trigger */
}

.latest-ads-type-selector {
  .type-toggle {
    color: #623bff;
  }
  &:hover .type-toggle {
    color: #fff;
  }
}

$ember-power-select-default-border-radius: 6px !default;
$ember-power-select-line-height: 2.3 !default;
$ember-power-select-border-color: #ced4da !default;
$ember-power-select-trigger-icon-color: #999 !default;
$ember-power-select-focus-outline: 0 !default;
$ember-power-select-opened-border-radius: 0.3125rem !default;
$ember-power-select-search-input-border-radius: 3px !default;
$ember-power-select-dropdown-margin: 3px !default;
$ember-power-select-dropdown-box-shadow: rgba(black, 0.172549) 0px 6px 12px 0px !default;
$ember-power-select-highlighted-color: inherit !default;
$ember-power-select-highlighted-background: #f5f5f5 !default;
$ember-power-select-selected-background: #f5f5f5 !default;
$ember-power-select-focus-border-color: #66afe9 !default;
$ember-power-select-focus-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
0 0 8px rgba(102, 175, 233, 0.6) !default;
$ember-power-select-dropdown-border: 0.0625rem solid $ember-power-select-border-color !default;
$ember-power-select-dropdown-contiguous-border: $ember-power-select-dropdown-border !default;
