.noUi-connect {
  background-color: #2b87db;
}

.noUi-connects {
  background-color: #ccc;
}

.noUi-horizontal .noUi-handle {
  border-color: #2b87db;
  border-radius: 50px;
  width: 24px;
  height: 24px;
  top: -10px;
  &:before, &:after, {
    display: none;
  }
}

.noUi-handle.noUi-handle-lower {
  right: -13px;
}

.noUi-handle.noUi-handle-upper {
  right: -11px;
}

.noUi-target {
  border: none;
}

.noUi-horizontal {
  height: 6px;
}
