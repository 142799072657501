/* styles for front/_pods/404/styles.scss */
._not-found_wbsjx5 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
}
._lottie-container_wbsjx5 {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    height: 100%;
  }
}

/* styles for front/_pods/components/button/bookmark/styles.scss */
._bookmark-btn_1owc79 {
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: rgba(107, 70, 193, 0.1) !important;
  border-radius: 50% !important;
  color: #6b46c1 !important;
  width: 32px;
  height: 32px;

  @media (min-width: 576px) {
    margin-right: -7px;
  }
}

._bookmark-btn_1owc79:hover,
._bookmark-btn_1owc79._active_1owc79 {
  color: #553c9a;
}

/* styles for front/_pods/components/button/group/styles.scss */
._radio-group-btn_nr3an0 label {
  border: 1px solid #c9c9c9 !important;
  color: #121212 !important;
  background-color: #fff !important;
  font-size: 13px;
}

._btn-active_nr3an0 + label {
  background-color: #e9f3fb !important;
}

/* styles for front/_pods/components/button/task/styles.scss */
._spinner_sswsge {
  display: inline-block;
}

button._button_sswsge {
  transition: all 0.15s ease-in-out;
  text-align: right;
}


/* styles for front/_pods/components/button/tether/styles.scss */
._tether-el_1uab6c {
  z-index: 99;
  box-shadow: -0.8px 0.25px 3px 2.5px rgba(0,0,0,.15);
  border-radius: 4px;
  background-color: #fff;
  padding: 16px;
  margin-top: 6px;
}

._btn_1uab6c._btn-active_1uab6c {
  border: 2px solid #65c0cf;
}

._icon_1uab6c {
  position: absolute;
  right: 10px;
  top: 11px;
}

._close-icon_1uab6c {
  width: 16px;
  height: 16px;
  right: 5px;
  top: 10px;
}

._close-icon_1uab6c:hover {
  background-color: rgba(#65c0cf, .2);
  border-radius: 50%;
}

._loading-icon_1uab6c {
  font-size: 10px;
}

._loading-icon_1uab6c {
  font-size: 10px;
}

/* styles for front/_pods/components/content-loader/load-card/styles.scss */
._wrapper_rdwh6n {
  width: 300px;
  height: 320px;
}

/* styles for front/_pods/components/content-loader/subscription-card/styles.scss */
._wrapper_1w1w0p {
  display: flex;
  width: 100%;
  height: 310px;
}

/* styles for front/_pods/components/content-loader/top-search/styles.scss */
._loader-wrapper_1tov7b {
  width: 380px;
  height: 82px;

  svg {
    border-radius: 24px;
  }
}

/* styles for front/_pods/components/custom/badge-selectors/styles.scss */
._truck-type-div_l70jt4 {
  // padding: 4px 20px;
  padding: 4px 0;

  ._truck-type_l70jt4 {
    white-space: nowrap;
    transform: scale(0.95);
    cursor: pointer;
    transition: 0.2s;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-transform: capitalize;
  }

  ._clear-truck-type-btn_l70jt4 {
    position: absolute;
    top: -4px;
    right: 0;
    width: 14px;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: red;
    color: white;
    cursor: pointer;
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }
}



/* styles for front/_pods/components/custom/checkbox/styles.scss */
._checkbox-wrapper_18umr6 {
  --s-xsmall: 0.625em;
  --s-small: 1.2em;
  --border-width: 1px;
  --c-primary: #5F11E8;
  --c-primary-20-percent-opacity: rgb(95 17 232 / 20%);
  --c-primary-10-percent-opacity: rgb(95 17 232 / 10%);
  --t-base: 0.4s;
  --t-fast: 0.2s;
  --e-in: ease-in;
  --e-out: cubic-bezier(.11,.29,.18,.98);
}

._checkbox-wrapper_18umr6 ._visuallyhidden_18umr6 {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

._checkbox-wrapper_18umr6 ._checkbox_18umr6 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
._checkbox-wrapper_18umr6 ._checkbox_18umr6 + ._checkbox_18umr6 {
  margin-top: var(--s-small);
}
._checkbox-wrapper_18umr6 ._checkbox__symbol_18umr6 {
  cursor: pointer;
  display: inline-block;
  display: flex;
  margin-right: calc(var(--s-small) * 0.5);
  border: var(--border-width) solid var(--c-primary);
  position: relative;
  border-radius: 0.1em;
  width: 1.5em;
  height: 1.5em;
  transition: box-shadow var(--t-base) var(--e-out), background-color var(--t-base);
  box-shadow: 0 0 0 0 var(--c-primary-10-percent-opacity);
}
._checkbox-wrapper_18umr6 ._checkbox__symbol_18umr6:after {
  content: "";
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  width: 0.25em;
  height: 0.25em;
  background-color: var(--c-primary-20-percent-opacity);
  opacity: 0;
  border-radius: 3em;
  transform: scale(1);
  transform-origin: 50% 50%;
}
._checkbox-wrapper_18umr6 ._checkbox_18umr6 ._icon-checkbox_18umr6 {
  width: 1em;
  height: 1em;
  margin: auto;
  fill: none;
  stroke-width: 3;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  color: var(--c-primary);
  display: inline-block;
}
._checkbox-wrapper_18umr6 ._checkbox_18umr6 ._icon-checkbox_18umr6 path {
  transition: stroke-dashoffset var(--t-fast) var(--e-in);
  stroke-dasharray: 30px, 31px;
  stroke-dashoffset: 31px;
}
._checkbox-wrapper_18umr6 ._checkbox__textwrapper_18umr6 {
  cursor: pointer;
  margin: 0;
  font-size: 0.9rem;
}

._checkbox-wrapper_18umr6 ._checkbox__trigger_18umr6:checked + ._checkbox__symbol_18umr6 ._icon-checkbox_18umr6 path {
  transition: stroke-dashoffset var(--t-base) var(--e-out);
  stroke-dashoffset: 0px;
}
._checkbox-wrapper_18umr6 ._checkbox__trigger_18umr6:focus + ._checkbox__symbol_18umr6 {
  box-shadow: 0 0 0 0.25em var(--c-primary-20-percent-opacity);
}

/* styles for front/_pods/components/custom/date-selector/styles.scss */
._truck-type-div_1mpcgg {
  width: 140px;
  padding: 4px 0;
  position: relative;

  ._truck-type_1mpcgg {
    white-space: nowrap;
    transform: scale(0.95);
    cursor: pointer;
    transition: 0.2s;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-transform: capitalize;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    outline: none;
    background: url('data:image/svg+xml;utf8,<svg fill="%23000000" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>') no-repeat right 10px center;
    background-size: 16px;

    &._date-selector_1mpcgg {
      background: #623bff !important;
      text-overflow: ellipsis;
      overflow: hidden !important;
      flex-flow: wrap;
      text-align: start;
      padding-right: 28px !important;
    }
  }

  ._clear-truck-type-btn_1mpcgg {
    position: absolute;
    top: 12px;
    right: 14px;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: red;
    color: white;
    cursor: pointer;
    font-size: 12px;

    svg {
      font-size: 7px;
    }
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }
}

/* styles for front/_pods/components/custom/input/styles.scss */
._input-group-append_1v1dtg {
  position: absolute;
  right: 0;
  top: 0;
  padding: 8px 12px;
  cursor: pointer;
  height: 100%;

  svg {
    color: #9AA3B0;
  }
}
._input-group-text_1v1dtg{
  height: 38px;
}

._input_1v1dtg {
  outline: none;
  border: 1px solid #ced4da !important;

  @media (max-width: 768px) {
    padding: 0.75rem 1rem !important;
    border-radius: 0.7rem !important;
  }
}

._search-input_1v1dtg {
  padding: 5px 0 5px 2.5rem !important;
  height: 45px;
}

._search-btn_1v1dtg {
  left: 0;
}

._error-msg_1v1dtg {
  font-size: 10px;
  font-weight: 400;
  color: #eb5757;
}
._cleanup-btn_1v1dtg {
  position: absolute;
  right: 0;
  margin-right: 1px;
  border: none !important;

  svg {
    font-size: 1.2rem;
    color: #888888;
  }

  @media (max-width: 768px) {
    top: 10px;
    margin-right: 10px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: #f0ecf9 !important;
    border-radius: 50% !important;
    width: 32px !important;
    height: 32px !important;
  }
}

._input-label_1v1dtg {
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 0.9rem;
  color: #2b3c5e;
}

._currency_1v1dtg {
  width: 65px !important;
  padding: 0 !important;
  border: none !important;
}

._select-currency_1v1dtg {
  width: 100% !important;
  margin: 0 !important;
  // border: none !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
}

// .input[type="number"] {
//   -moz-appearance: textfield; /* Firefox uchun */
// }

._input_1v1dtg[type="number"]::-webkit-inner-spin-button,
._input_1v1dtg[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none; /* Chrome va Safari uchun */
  margin: 0;
}

/* styles for front/_pods/components/custom/select/styles.scss */
._input-label_v96qby {
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 0.9rem;
  color: #2b3c5e;
}

._error-msg_v96qby {
  font-size: 10px;
  font-weight: 400;
  color: #eb5757;
}

._sort-btn_v96qby {
  color: #999;
  position: absolute;
  right: 16px;
}

._power-selector_v96qby {
  width: 100%;
  //height: 44px;
  display: flex;
  align-items: center;

  & > span:first-of-type {
    margin-left: 10px;
  }
}

._cleanup-btn_v96qby {
  position: absolute;
  right: 0;
  top: 5px;
  margin-right: 15px;
  border: none !important;

  svg {
    font-size: 1.2rem;
    color: #888888;
  }

  &:hover {
    background-color: transparent !important;
    svg {
      color: red;
    }
  }

  @media (max-width: 768px) {
    top: 10px;
    margin-right: 10px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: #f0ecf9 !important;
    border-radius: 50% !important;
    width: 32px !important;
    height: 32px !important;
  }
}

._loading-btn_v96qby {
  position: absolute;
  top: 10px;
  right: 25px;

  @media (max-width: 768px) {
    top: 16px;
  }
}

/* styles for front/_pods/components/custom/textarea/styles.scss */
._input-group-append_17pg82 {
  position: absolute;
  right: 0;
  top: 0;
  padding: 8px 12px;
  cursor: pointer;

  svg {
    color: #9AA3B0;
  }
}

._input_17pg82 {
  outline: none;
  border: 1px solid #ced4da;

  @media (max-width: 768px) {
    padding: 0.75rem 1rem !important;
    border-radius: 0.7rem !important;
  }
}

._search-input_17pg82 {
  padding: 5px 0 5px 2.5rem !important;
  height: 45px;
}

._error-msg_17pg82 {
  font-size: 10px;
  font-weight: 400;
  color: #eb5757;
}

._input-label_17pg82 {
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 0.9rem;
  color: #2b3c5e;
}

/* styles for front/_pods/components/empty-state/styles.scss */
._container_a6n95h {
  max-width: 500px;
  text-align: center;
  color: rgba(1, 41, 112, 0.6);
}

/* styles for front/_pods/components/filters/checkbox/styles.scss */
._checkbox-input_1rhv6h {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0 !important;
  z-index: -1;
  cursor: pointer;
}

._checkbox-container_1rhv6h {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  line-height: 20px;

  ._checkbox-box_1rhv6h {
    display: inline-flex;
    position: relative;
    flex: 0 0 auto;
    border: 1px solid #d0d3d9;
    border-radius: 14%;
    width: 16px;
    height: 16px;
    content: '';

    &._large-checkbox_1rhv6h {
      width: 22px;
      height: 22px;
    }
  }

  ._box-checked_1rhv6h {
    border-color: #0468ff;
    background: #0468ff;
  }

  ._checkbox-icon_1rhv6h {
    display: flex;
    justify-content: center;
    align-items: center;
    // display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    font-size: 14px;
    line-height: 20px;

    svg {
      fill: #fff;
      // width: 10px;
    }
  }

  ._checkbox-label_1rhv6h {
    margin-left: 12px;
    white-space: nowrap;
  }
}

._c-pointer_1rhv6h {
  cursor: pointer;
}

/* styles for front/_pods/components/filters/range/styles.scss */
._filter-slider-wrapper_bbyi6x {
  // max-width: 400px;
  margin: 8px 11px;
}

._filter-input-wrapper_bbyi6x {
  margin-top: 24px;
  display: flex;
  width: 100%;
}

._filter-input-text_bbyi6x {
  background-color: #e9ecef;
  padding: 0 10px;
  line-height: 2.3;
  min-height: 2.3em;
  margin-right: 0;
  border: 1px solid #ced4da;
  border-right: none;
  border-radius: 4px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 0.875rem;
}

._filter-input_bbyi6x {
  flex-grow: 1;
  min-width: 70px;
  width: 100%;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

._title_bbyi6x {
  display: flex;
  text-transform: capitalize;
  margin: 0;
  cursor: pointer;
  user-select: none;
}

._title-disabled_bbyi6x {
  color: #666;
}

._input-group_bbyi6x {
  display: flex;
  width: 100%;
  align-items: center;
  & > div {
    width: 100%;
  }
}

/* styles for front/_pods/components/filters/set/styles.scss */
._list_15d4mz {
  gap: 8px;
  margin: 0;
  padding: 0;

  & > li {
    list-style: none;
  }

  ._horizontal-list-item_15d4mz {
    cursor: pointer;
    border: 1px solid #f4f4f4;
    border-radius: 4px;
    background: none;
    padding: 8px 16px;
    min-width: 36px;
    //color: #121212;
    font-size: 14px;
    text-align: center;
    background-color: white;

    &:hover {
      background-color: #e9f3fb;
      border-color: #e9f3fb;
    }

    &._horizontal-list-item-active_15d4mz {
      border-color: #2b87db;
      background-color: #e9f3fb;
      color: #2b87db;

      svg {
        color: #2b87db;
      }
    }

    svg {
      color: #828385;
    }
  }
}

._horizontal-list_15d4mz {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    // flex-direction: column;
  }
}

._vertical-list_15d4mz {
  display: flex;
  flex-direction: column;

  ._horizontal-list-item_15d4mz {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
  }
}

/* styles for front/_pods/components/form/register/styles.scss */
._agreement-checkbox_hh6ld6 {
  font-size: 12px;
  line-height: 1rem;
}

._agreement-checkbox-input_hh6ld6 {
  border-color: #b1b7bc !important;
}

/* styles for front/_pods/components/form/vehicle/styles.scss */
._add-more-btn_1jhcwc {
  bottom: -86px;
  background: white;
  left: 0;
}

._additional-actions_1jhcwc {
  margin-top: 0.65rem !important;
  align-items: center;

  button {
    padding: 0.125rem 0.45rem !important;
  }
}

._divider_1jhcwc {
  width: 100%;
  display: flex;
  div {
    display: block;
    padding: 0 10px;
    width: 100%;
    border-top: 1px solid #ccc;
    margin-bottom: 8px;
  }
}

/* styles for front/_pods/components/header/messages/styles.scss */
._no-carret_95ocdu {
  &:after {
    display: none !important;
  }
}

/* styles for front/_pods/components/header/notifications/styles.scss */
._no-carret_ia2584 {
  &:after {
    display: none !important;
  }
}

/* styles for front/_pods/components/header/styles.scss */
._login-icon_ccp6t {
  @media (max-width: 991px) {
    font-size: 22px;
  }
}

/* styles for front/_pods/components/language-switcher-mobile/styles.scss */
._bs-dropdown_8cnejx {
  transition: all 0.1s ease-in-out;

  & > div {
    min-width: 3rem;
    margin-top: 0.9rem !important;
    margin-bottom: 0.9rem !important;
    margin-left: -0.4rem !important;
    border-radius: 2rem;
    padding: 10px !important;
    box-shadow: 0px 0px 4px #623bff, inset 0px 2px 10px #fff;
    svg {
      width: 1.7rem !important;
      height: 1.4rem;
      margin: 0 !important;
    }
  }
}

._lang-button_8cnejx {
  height: 50px;
  width: 50px;
  border-radius: 50% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  border-color: #dee2e6 !important;
  box-shadow: 0px 0px 2px #aaa, inset 0px 2px 10px #fff;

  &::after {
    content: none !important;
  }

  svg {
    width: 2rem;
    height: 1.7rem;
  }
}

._item_8cnejx {
  height: 40px;
  width: 40px !important;
  border-radius: 50% !important;
  border: 1px solid #dee2e6 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 !important;

  &:first-child {
   margin-bottom: 5px !important;
  }
}

/* styles for front/_pods/components/language-switcher/styles.scss */
._bs-dropdown_1polf2 {
  transition: all 0.1s ease-in-out;
}

._switcher_1polf2::after {
  content: none !important;
}

/* styles for front/_pods/components/load-card/styles.scss */
._load-card_a5qft1 {
  width: 100%;
  margin-bottom: 0 !important;

  &._has-footer_a5qft1 {
    margin-bottom: 5rem !important;
  }
}

._load-list-card_a5qft1 {
  width: 100%;
  border-radius: 24px !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  margin-bottom: 20px !important;
}

._airline-logo_a5qft1 {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

._load-path_a5qft1 {
  position: relative;
  flex: auto;
  align-self: start;
  max-width: 160px;

  ._distance_a5qft1 {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -25px;
    font-size: 14px;
    font-weight: 600;
    color: #6b46c1;
  }

  ._hours_a5qft1 {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -40px;
    font-size: 12px;
    font-weight: 600;
    color: #CDD4DA;
  }
}

._path-line_a5qft1 {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background: repeating-linear-gradient(
      90deg,
      #6b46c1,
      #6b46c1 5px,
      transparent 5px,
      transparent 10px
  );
  opacity: 0.3;
}

._direction-icon_a5qft1 {
  background-color: #ffffff;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid #CDD4DA;
  display: inline-block;
  position: relative;
  z-index: 1;
  color: #6b46c1;
  transform: rotate(90deg);
}

._icon-wrapper_a5qft1 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(107, 70, 193, 0.1);
  border-radius: 50%;
  color: #6b46c1;
  width: 32px;
  height: 32px;
}

._danger_a5qft1 {
  color: red !important;
  background-color: rgba(191, 67, 67, 0.1) !important;
}

@media (max-width: 576px) {
  ._load-card_a5qft1 {
    font-size: 0.9rem;
  }
}

._display-6_a5qft1 {
  font-size: 1.4rem;
}

._price_a5qft1 {
  width: 100px;
}

._truck-icon_a5qft1 {
  color: #6b46c1;
  font-size: 12px;
}

/* styles for front/_pods/components/load-list-card/styles.scss */
._load-card_1ousu9 {
  width: 100%;
  border-radius: 24px !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  margin-bottom: 20px !important;
}

._airline-logo_1ousu9 {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

._bookmark-btn_1ousu9 {
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: rgba(107, 70, 193, 0.1) !important;
  border-radius: 50% !important;
  color: #6b46c1 !important;
  width: 32px;
  height: 32px;

  @media (min-width: 576px) {
    margin-right: -7px;
  }
}

._bookmark-btn_1ousu9:hover,
._bookmark-btn_1ousu9._active_1ousu9 {
  color: #553c9a;
}

._load-path_1ousu9 {
  position: relative;

  ._distance_1ousu9 {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -25px;
    font-size: 14px;
    font-weight: 700;
    color: #6b46c1;
  }
}

._path-line_1ousu9 {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background: repeating-linear-gradient(
    90deg,
    #6b46c1,
    #6b46c1 5px,
    transparent 5px,
    transparent 10px
  );
  opacity: 0.3;
}

._direction-icon_1ousu9 {
  background-color: #ffffff;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid #CDD4DA;
  display: inline-block;
  position: relative;
  z-index: 1;
  color: #6b46c1;
  transform: rotate(90deg);
}

._icon-wrapper_1ousu9 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(107, 70, 193, 0.1);
  border-radius: 50%;
  color: #6b46c1;
  width: 32px;
  height: 32px;
}

@media (max-width: 576px) {
  ._load-card_1ousu9 {
    font-size: 0.9rem;
  }
}

._display-6_1ousu9 {
  font-size: 1.4rem;
}

._price_1ousu9 {
  width: 100px;
}

._truck-icon_1ousu9 {
  color: #6b46c1;
  font-size: 12px;
}


/* styles for front/_pods/components/load-list-item/styles.scss */
._item_j24iy3 {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
  background-color: #fff;
  margin-bottom: 12px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  border: 1px solid #e2e0e0;
}

._destination-names_j24iy3 {
  font-size: 1.15rem;
}

._small-text_j24iy3 {
  font-size: 14px;
}

._ad-type_j24iy3 {
  position: absolute;
  top: 0;
  right: 0;
  color: #623bff;
}

/* styles for front/_pods/components/load-route-selector/styles.scss */
._data-card_116m8u {
  border-radius: 24px !important;

  ._data-item_116m8u {
    width: 33%;
  }

  ._path-line_116m8u {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    background: repeating-linear-gradient(
        90deg,
        #6b46c1,
        #6b46c1 5px,
        transparent 5px,
        transparent 10px
    );
    opacity: 0.3;
  }

  ._restart-btn_116m8u {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: red;
    color: white;
    font-size: 10px;
    cursor: pointer;
  }

  ._arrow-btn_116m8u {
    font-size: 20px;
  }

  ._switch-btn_116m8u {
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 50% !important;
    width: 40px;
    height: 40px;
    border-color: #CDD4DA !important;
    color: #4154f1 !important;
    z-index: 2;
    background: white;

    &:hover {
      background: white;
    }

    &:disabled {
      background: white;
      color: #CDD4DA !important;
      opacity: 1;
    }
  }
}

/* styles for front/_pods/components/mobile-navbar/styles.scss */
// .hide-scroller {
//   &::-webkit-scrollbar {
//     display: none;
//   }

//   -ms-overflow-style: none; /* IE and Edge */
//   scrollbar-width: none; /* Firefox */
// }


._mobile-nav_h541zz {
  background-color: #9f9ccc;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;

  ._nav-btn_h541zz {
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;

    svg {
      font-size: 0.8rem;
      color: #000;
    }
  }

  ._nav-title_h541zz {
    flex: 1;
    text-align: center;
    color: #fff;
    font-family: 'Nunito', sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
  }
}

/* styles for front/_pods/components/modal/auth/styles.scss */
._bg-image-vertical_3ex0vq {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto 100%;
  object-fit: cover;
  object-position: left;
}

._btn_3ex0vq {
  text-transform: uppercase;
}

._auth_3ex0vq {
  button {
    width: 100% !important;
    border-radius: 4px !important;

    width: 50% !important;
    &:nth-child(1) {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    &:nth-child(2) {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}

._auth-form_3ex0vq {
  width: 430px;
  @media (max-width: 778px) {
    width: 350px;
  }
}

/* styles for front/_pods/components/modal/dialog/styles.scss */
._dialog_1mvvr6 {
  position: relative;
  width: 504px;
  overflow: scroll;
  padding: 24px;
  background-color: #fff;
  border: 1px solid #f3f3f0;
  border-radius: 10px;
  box-shadow: 0 3px 12px 0 rgba(16, 16, 15, 0.64);

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media (max-width: 575.98px) {
    height: 100dvh !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0;
    border: unset;

    // padding: 24px 28px;
    padding: 24px;
  }

  ._header_1mvvr6 {
    width: 100%;

    &._fixed_1mvvr6 {
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      background-color: white;
      padding: 1rem 24px;
      z-index: 50;
      box-shadow: 0px 0px 3px #aaa, inset 0px 2px 10px #fff;

      @media (min-width: 576px) {
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
      }

      ._close-button_1mvvr6 {
        position: inherit;
      }
    }

    ._close-button_1mvvr6 {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 10;

      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(107, 70, 193, 0.1);
      border-radius: 50%;
      color: #6b46c1;
      width: 40px;
      height: 40px;

      svg {
        font-size: 20px;
      }

      @media (max-width: 576px) {
        top: 10px;
        right: 10px;
      }
    }
  }


  ._mobile-header_1mvvr6 {
    display: none;
  }

  &._telegram_1mvvr6 {
    padding-top: 12rem;

    ._top-offset_1mvvr6 {
      position: fixed;
      top: 0;
      background-color: #9f9ccc;
      height: 6rem;
      width: 100%;
      z-index: 10;
    }

    ._close-button_1mvvr6 {
      top: 105px;
      right: 10px;
    }

    ._header_1mvvr6 {
      display: none;
    }

    ._content_1mvvr6 {
      margin: 0 !important;
    }

    ._mobile-header_1mvvr6 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      position: fixed;
      top: 6rem;
      width: 100%;
      z-index: 10;
      background-color: #9f9ccc;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;

      ._nav-title_1mvvr6 {
        color: #fff;
        font-family: 'Nunito', sans-serif;
        font-size: 1.2rem;
        font-weight: 600;
      }

      ._nav-btn_1mvvr6 {
        border-radius: 50%;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;

        svg {
          font-size: 0.8rem;
          color: #000;
        }
      }
    }
  }
}

._import-adverts-modal_1mvvr6 {
  width: 800px !important;
}

// .illustration {
//   margin-bottom: 24px;
// }

._title_1mvvr6 {
  margin: 0;
  color: var(--content-primary-a);
  flex: 1;
}

._description_1mvvr6 {
  margin-bottom: 0;
  color: var(--content-primary-a);
}

._content_1mvvr6 {
  width: 100%;
  margin-top: 30px;
  display: inline-block;

  &._with-offset_1mvvr6 {
    margin-top: 70px;

    @media (max-width: 576px) {
      margin-top: 65px;
    }
  }
}

._actions_1mvvr6 {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 24px;
}

._footer_1mvvr6 {}

/* styles for front/_pods/components/modal/load-card/styles.scss */
._block_1qto2z {
  text-align: left;
  padding: 12px 14px;
  //background-color: #f9f9f9;
  background-color: white;
  border: 1px solid #efefef;
  border-radius: 8px;
}

._footer_1qto2z {
  margin-top: 10px;
  @media (max-width: 576px) {
    background-color: #fff;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1) !important;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 24px;
    margin-top: 0;
  }
}

/* styles for front/_pods/components/modal/subscription/styles.scss */
._card_116oxv {
  height: 350px;
  margin-bottom: 20px;
  cursor: pointer;

  ._card-body_116oxv {

  }

  ._card-footer_116oxv {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  @media (max-width: 768px) {
    height: 250px;
  }
}

._active_116oxv {
  ._card-body_116oxv {
    border-color: #0c63e4 !important;
  }
}

._subscription-dialog_116oxv {
  width: 800px !important;

  @media (max-width: 940px) {
    width: 600px !important;
  }
  @media (max-width: 640px) {
    width: 400px !important;
  }

  ._grid-container_116oxv {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: row;
    gap: 20px;

    @media (max-width: 940px) {
      grid-template-columns: repeat (2, 290px) !important;
    }
    @media (max-width: 640px) {
      grid-template-columns: repeat (1, 290px) !important;
    }
  }
}
/* styles for front/_pods/components/modal/vehicle-card/styles.scss */
._block_1o3723 {
  text-align: left;
  padding: 12px 14px;
  background-color: #f9f9f9;
  border: 1px solid #efefef;
  border-radius: 8px;
}

._footer_1o3723 {
  margin-top: 10px;
  @media (max-width: 576px) {
    background-color: #fff;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1) !important;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 24px;
    margin-top: 0;
  }
}

/* styles for front/_pods/components/my-load-item/styles.scss */
._item_1majpd {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
  background-color: #fff;
  margin-bottom: 12px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  border: 1px solid #e2e0e0;
}

._destination-names_1majpd {
  font-size: 1.15rem;
}

._small-text_1majpd {
  font-size: 14px;
}

/* styles for front/_pods/components/my-vehicle-item/styles.scss */
._item_rzcfhf {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
  background-color: #fff;
  margin-bottom: 12px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  border: 1px solid #e2e0e0;
}

._destination-names_rzcfhf {
  font-size: 1.15rem;
}

._small-text_rzcfhf {
  font-size: 14px;
}

._icon-margin_rzcfhf {
  margin-top: 3px;
}

/* styles for front/_pods/components/page-loading/styles.scss */
._loader-backdrop_1j1hat {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

._loader-spinner_1j1hat {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* styles for front/_pods/components/page-selector/styles.scss */
._wrapper-pagination_p4lpip {
  display: flex;
  align-items: center;
  border: 1px solid #dcdcdc;
  border-radius: 20px;
  padding: 4px 10px 4px 16px;
}

._wrapper_p4lpip {
  position: relative;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

._per-page-container_p4lpip {
  display: flex;
  align-items: center;
}

._options_p4lpip {
  background-color: #eee;
  display: flex;
  align-items: center;
  width: auto;
  height: 20px;
  overflow: hidden;
  border: 1px solid #eee;
  border-radius: 13px;
}

._option_p4lpip {
  cursor: pointer;
  padding: 0 4px;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  &:not(:first-child) {
    border-left: 1px solid #ddd;
  }

  &:hover {
    background-color: #ccc;
  }

  &._active_p4lpip {
    background-color: #ddd;
    &:hover {
      background-color: #ccc;
    }
  }
}

._summary_p4lpip {
  flex: auto;
  margin-right: 16px;
  font-size: 14px;
}

/* styles for front/_pods/components/profile/settings/styles.scss */
._form_1od0mj {
  position: relative;

  &._disabled_1od0mj {
    pointer-events: none;

    ._cover_1od0mj {
      display: block;
    }
  }
}

._cover_1od0mj {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 1;
  pointer-events: none;
}

/* styles for front/_pods/components/show-more-text/styles.scss */
._text-container_1d9yo3 {
  position: relative;
  overflow: hidden;
}

._truncated-text_1d9yo3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 35px;
}

._full-text_1d9yo3 {
  display: block;
  padding-right: 35px;
}

._expand-btn_1d9yo3 {
  position: absolute;
  top: 0;
  right: 0;

  button {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
}

/* styles for front/_pods/components/spinner/inline/styles.scss */
$size: 4px;

._spinner_1ru0a3 {
  display: inline-block;
  position: relative;
  width: 42px;
  height: $size * 1.6 + 2;
}

._spinner_1ru0a3 div {
  position: absolute;
  top: 0px;
  width: $size * 1.6;
  height: $size * 1.6;
  border-radius: 50%;
  background: #0d6efd;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
._spinner_1ru0a3 div:nth-child(1) {
  left: $size;
  animation: _spinner1_1ru0a3 0.6s infinite;
}
._spinner_1ru0a3 div:nth-child(2) {
  left: $size;
  animation: _spinner2_1ru0a3 0.6s infinite;
}
._spinner_1ru0a3 div:nth-child(3) {
  left: 4 * $size;
  animation: _spinner2_1ru0a3 0.6s infinite;
}
._spinner_1ru0a3 div:nth-child(4) {
  left: 7 * $size;
  animation: _spinner3_1ru0a3 0.6s infinite;
}
@keyframes _spinner1_1ru0a3 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes _spinner3_1ru0a3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes _spinner2_1ru0a3 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(3 * $size, 0);
  }
}

/* styles for front/_pods/components/spinner/page-load/styles.scss */
._spinner-container_pejhm8 {
  width: 100%;
  height: 100dvh;
  position: absolute;
  z-index: 1200;
  background-color: transparent;

  background-image:
    radial-gradient(
        #623bff 30%,
        hsla(0,40%,100%,0) 35%
    ),
    radial-gradient(
        #623bff 30%,
        hsla(0,40%,100%,0) 35%
    ),
    radial-gradient(
        #623bff 30%,
        hsla(0,40%,100%,0) 35%
    ),
    radial-gradient(
        #623bff 30%,
        hsla(0,40%,100%,0) 35%
    ),
    radial-gradient(
        #623bff 30%,
        hsla(0,40%,100%,0) 35%
    ),
    radial-gradient(
        #623bff 30%,
        hsla(0,40%,100%,0) 35%
    ),
    radial-gradient(
        #623bff 30%,
        hsla(0,40%,100%,0) 35%
    ),
    radial-gradient(
        #623bff 30%,
        hsla(0,40%,100%,0) 35%
    );

  background-position:
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%;
  background-size: 1.5em 1.5em;
  background-repeat: no-repeat;

  animation: _bloom_pejhm8 2.5s infinite;
}

@keyframes _bloom_pejhm8 {
  6.25% {
    background-position:
      50% calc(50% - 1.5em),
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%;
  }
  12.5% {
    background-position:
      50% calc(50% - 1.5em),
      calc(50% + 1em) calc(50% - 1em),
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%;
  }
  18.75% {
    background-position:
      50% calc(50% - 1.5em),
      calc(50% + 1em) calc(50% - 1em),
      calc(50% + 1.5em) 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%;
  }
  25% {
    background-position:
      50% calc(50% - 1.5em),
      calc(50% + 1em) calc(50% - 1em),
      calc(50% + 1.5em) 50%,
      calc(50% + 1em) calc(50% + 1em),
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%;
  }
  31.25% {
    background-position:
      50% calc(50% - 1.5em),
      calc(50% + 1em) calc(50% - 1em),
      calc(50% + 1.5em) 50%,
      calc(50% + 1em) calc(50% + 1em),
      50% calc(50% + 1.5em),
      50% 50%,
      50% 50%,
      50% 50%;
  }
  37.5% {
    background-position:
      50% calc(50% - 1.5em),
      calc(50% + 1em) calc(50% - 1em),
      calc(50% + 1.5em) 50%,
      calc(50% + 1em) calc(50% + 1em),
      50% calc(50% + 1.5em),
      calc(50% - 1em) calc(50% + 1em),
      50% 50%,
      50% 50%;
  }
  43.75% {
    background-position:
      50% calc(50% - 1.5em),
      calc(50% + 1em) calc(50% - 1em),
      calc(50% + 1.5em) 50%,
      calc(50% + 1em) calc(50% + 1em),
      50% calc(50% + 1.5em),
      calc(50% - 1em) calc(50% + 1em),
      calc(50% - 1.5em) 50%,
      50% 50%;
  }
  50% {
    background-position:
      50% 50%,
      calc(50% + 1em) calc(50% - 1em),
      calc(50% + 1.5em) 50%,
      calc(50% + 1em) calc(50% + 1em),
      50% calc(50% + 1.5em),
      calc(50% - 1em) calc(50% + 1em),
      calc(50% - 1.5em) 50%,
      calc(50% - 1em) calc(50% - 1em);
  }
  56.25% {
    background-position:
      50% 50%,
      50% 50%,
      calc(50% + 1.5em) 50%,
      calc(50% + 1em) calc(50% + 1em),
      50% calc(50% + 1.5em),
      calc(50% - 1em) calc(50% + 1em),
      calc(50% - 1.5em) 50%,
      calc(50% - 1em) calc(50% - 1em);
  }
  62.5% {
    background-position:
      50% 50%,
      50% 50%,
      50% 50%,
      calc(50% + 1em) calc(50% + 1em),
      50% calc(50% + 1.5em),
      calc(50% - 1em) calc(50% + 1em),
      calc(50% - 1.5em) 50%,
      calc(50% - 1em) calc(50% - 1em);
  }
  68.75% {
    background-position:
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% calc(50% + 1.5em),
      calc(50% - 1em) calc(50% + 1em),
      calc(50% - 1.5em) 50%,
      calc(50% - 1em) calc(50% - 1em);
  }
  75% {
    background-position:
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      calc(50% - 1em) calc(50% + 1em),
      calc(50% - 1.5em) 50%,
      calc(50% - 1em) calc(50% - 1em);
  }
  81.25% {
    background-position:
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      calc(50% - 1.5em) 50%,
      calc(50% - 1em) calc(50% - 1em);
  }
  87.5% {
    background-position:
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      calc(50% - 1em) calc(50% - 1em);
  }
}

/* styles for front/_pods/components/subscription-active-card/styles.scss */
._card_1s1gs8 {
  width: 100%;
}

._calendar-ico_1s1gs8 {
  width: 32px;
  height: 32px;
}

._check-ico_1s1gs8 {
  width: 16px;
  height: 16px;
}

._title_1s1gs8 {
  font-size: 21px;
  font-weight: 500;
  color: #012970;
  font-family: "Poppins", sans-serif;
}

._price_1s1gs8 {
  font-size: 28px;
  color: #012970;
  font-weight: 700;
}

/* styles for front/_pods/components/subscription-card/styles.scss */
._card_6azb7k {
  margin-bottom: 20px;
  cursor: pointer;

  ._card-body_6azb7k {
    height: 290px;
    @media (min-width: 992px) {
      height: 330px;
    }
  }

  ._card-footer_6azb7k {
    position: absolute;
    bottom: 8px;
    left: 8px;
    right: 8px;
  }
  
  ._card-price_6azb7k {
    position: absolute;
    width: 100%;
    bottom: 50px;
  }

  span {
    font-size: 30px;
    color: #012970;
  }
}

._active_6azb7k {
  ._card-body_6azb7k {
    border-color: #0c63e4 !important;
  }
}

/* styles for front/_pods/components/table/cell/badges/search/styles.scss */
._cursor_15exj5 {
  cursor: pointer;
}

/* styles for front/_pods/components/table/cell/city-name/styles.scss */
._cursor_nbfz0v {
  cursor: pointer;
}

/* styles for front/_pods/components/table/cell/image/styles.scss */
._image_pm8wf9 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border: 1px solid #DCDCDC;
  border-radius: 4px;

  svg {
    color: #DCDCDC;
  }
}

/* styles for front/_pods/components/table/cell/info/styles.scss */
._cursor_1uvp4b {
  cursor: pointer;
}

._icon_1uvp4b {
  width: 25px;
  font-size: 14px;
}

._label_1uvp4b {
  font-size: 14px;
}

/* styles for front/_pods/components/table/cell/listing-actions/styles.scss */
._dropdown-menu_qazkzk {
  border: 1px solid #DCDCDC;
  border-radius: 4px;

  button {
    width: 100%;
    border-radius: 0;

    &:hover {
      background-color: #cccccc;
    }
  }
}

/* styles for front/_pods/components/table/cell/vehicle-destinations/styles.scss */
._cursor_132p2y {
  cursor: pointer;
}

/* styles for front/_pods/components/table/cell/vehicle-locations/styles.scss */
._cursor_1m9i6m {
  cursor: pointer;
}

._icon_1m9i6m {
  //width: 30px;
  font-size: 14px;
}

._label_1m9i6m {
  font-size: 14px;
}


/* styles for front/_pods/components/table/styles.scss */
._table_1kur33 {
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
}

/* styles for front/_pods/components/table/vehicle-load/styles.scss */
._table_14udy7 {
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
}

._row_14udy7 {
  cursor: pointer;
}

/* styles for front/_pods/components/top-searches-card/styles.scss */
._data-card_65nkvx {
  width: 380px;
  border-radius: 24px !important;
  cursor: pointer;
  border: 1px solid #e2e0e0 !important;

  @media (max-width: 768px) {
    width: 100%;
  }

  ._data-item_65nkvx {
    flex: 1
  }

  ._path-line_65nkvx {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    background: repeating-linear-gradient(
        90deg,
        #6b46c1,
        #6b46c1 5px,
        transparent 5px,
        transparent 10px
    );
    opacity: 0.3;
  }

  ._arrow-btn_65nkvx {
    font-size: 20px;
  }

  ._switch-btn_65nkvx {
    display: flex !important;
    align-items: center;
    justify-content: center;
    //border-radius: 50% !important;
    width: 40px;
    height: 40px;
    border: none;
    //border-color: #CDD4DA !important;
    color: #4154f1 !important;
    z-index: 2;
    background: white;

    &:hover {
      background: white;
    }

    &:disabled {
      background: white;
      color: #CDD4DA !important;
      opacity: 1;
    }
  }

  ._data-card-footer_65nkvx {
    color: #798eb3;
    width: 100%;
    text-align: center;
    font-size: 15px;
  }
}

/* styles for front/_pods/components/vehicle-card/styles.scss */
._vehicle-card_48reez {
  width: 100%;
  margin-bottom: 0 !important;

  @media (max-width: 576px) {
    font-size: 0.9rem;
  }

  &._has-footer_48reez {
    margin-bottom: 5rem !important;
  }
}

._vehicle-list-card_48reez {
  width: 100%;
  border-radius: 24px !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  margin-bottom: 20px !important;

  @media (max-width: 576px) {
    font-size: 0.9rem;
  }
}

._bookmark-btn_48reez {
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: rgba(107, 70, 193, 0.1) !important;
  border-radius: 50% !important;
  color: #6b46c1 !important;
  width: 32px;
  height: 32px;

  @media (min-width: 576px) {
    margin-right: -7px;
  }
}

._bookmark-btn_48reez:hover,
._bookmark-btn_48reez._active_48reez {
  color: #553c9a;
}

._icon-wrapper_48reez {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(107, 70, 193, 0.1);
  border-radius: 50%;
  color: #6b46c1;
  width: 32px;
  height: 32px;
}

._display-6_48reez {
  font-size: 1.4rem;
}

._price_48reez {
  width: 100px;
}

._truck-icon_48reez {
  color: #6b46c1;
  font-size: 12px;
}

/* styles for front/_pods/components/vehicle-list-card/styles.scss */
._vehicle-card_vdo6t0 {
  width: 100%;
  border-radius: 24px !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  margin-bottom: 20px !important;

  @media (max-width: 576px) {
    font-size: 0.9rem;
  }
}

._bookmark-btn_vdo6t0 {
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: rgba(107, 70, 193, 0.1) !important;
  border-radius: 50% !important;
  color: #6b46c1 !important;
  width: 32px;
  height: 32px;

  @media (min-width: 576px) {
    margin-right: -7px;
  }
}

._bookmark-btn_vdo6t0:hover,
._bookmark-btn_vdo6t0._active_vdo6t0 {
  color: #553c9a;
}

._icon-wrapper_vdo6t0 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(107, 70, 193, 0.1);
  border-radius: 50%;
  color: #6b46c1;
  width: 32px;
  height: 32px;
}

._display-6_vdo6t0 {
  font-size: 1.4rem;
}

._price_vdo6t0 {
  width: 100px;
}

._truck-icon_vdo6t0 {
  color: #6b46c1;
  font-size: 12px;
}


/* styles for front/_pods/components/vehicle-list-item/styles.scss */
._item_e3mh3p {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
  background-color: #fff;
  margin-bottom: 12px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  border: 1px solid #e2e0e0;
}

._destination-names_e3mh3p {
  font-size: 1.15rem;
}

._small-text_e3mh3p {
  font-size: 14px;
}

._icon-margin_e3mh3p {
  margin-top: 3px;
}

._ad-type_e3mh3p {
  position: absolute;
  top: 0;
  right: 0;
  color: #623bff;
}

/* styles for front/_pods/components/view-selector/styles.scss */
._btn-view_1woavn {
  label {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 12px;
    background-color: #fff;
    color: #000;
    border: 1px solid #dcdcdc;
    border-radius: 6px;
    //width: 50px;

    &:hover {
      background-color: #5e67b0 !important;
      border-color: #5e67b0 !important;
    }
  }

  svg {
    vertical-align: -7px;
    font-size: 1rem;
  }
}

._small-size_1woavn {
  height: 37px !important;
}

._label-50_1woavn {
  label {
    width: 50%;
  }
}

._btn-custom_1woavn:checked + label {
  // color: var(--bs-btn-active-color);
  // background-color: #4154f1 !important;
  background-color: #623bff !important;
  border-color: #623bff !important;
}

._pill-custom_1woavn {
  padding: 0.6rem !important;
  font-size: 1.1rem !important;
}

/* styles for front/_pods/loading/styles.scss */
._container_h1tw4v {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 100dvh;
   width: 100%;
}

/* styles for front/_pods/login/styles.scss */
._bg-image-vertical_19csmq {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto 100%;
  object-fit: cover;
  object-position: left;
}

// @media (max-width: 778px) {
//   .h-custom-2 {
//     margin-top: -5rem !important;
//   }
// }

._btn_19csmq {
  text-transform: uppercase;
}

._auth_19csmq {
  //margin-top: 24px;

  button {
    width: 100% !important;
    border-radius: 4px !important;

    // @media (min-width: 576px) {
      width: 50% !important;
      &:nth-child(1) {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }

      &:nth-child(2) {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    // }
  }
}

._auth-form_19csmq {
  width: 430px;
  @media (max-width: 778px) {
    width: 350px;
  }
}

._search-icon_19csmq {
  padding: 0.75rem 1rem;
  font-size: 1.2rem;
}

/* styles for front/_pods/main/bookmarks/styles.scss */
._view-selector_93nflu {
  width: 100%;

  @media (min-width: 992px) {
    width: 300px;
    flex: none;
  }
}

._grid_93nflu {
  display: grid;
  column-gap: 4rem;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* styles for front/_pods/main/help/styles.scss */
._needly-card_1tpsrk {
  transition: box-shadow 0.3s ease-in-out;
}
._needly-card_1tpsrk:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}
._needly-accordion-button_1tpsrk {
  transition: background-color 0.2s ease-in-out;
}
._needly-accordion-button_1tpsrk:hover {
  background-color: #f8f9fa;
}
._needly-icon_1tpsrk {
  width: 1.25rem;
  height: 1.25rem;
}

/* styles for front/_pods/main/index/styles.scss */
._search-component_55i6gj {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;

  div {
    flex: 1;
  }
}

._lg-btn_55i6gj {
  padding: 0.40rem 1rem !important;
  @media (max-width: 768px) {
    padding: 0.65rem 1rem !important;
  }
}

._search_55i6gj {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding-bottom: 1.2rem;
}

._c-pointer_55i6gj {
  cursor: pointer;
}

._new-ad_55i6gj {
  width: 380px;
  border-radius: 24px !important;
  cursor: pointer;
  background-color: transparent !important;
  border: 2px solid #623bff !important;
  gap: 0.5rem;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #623bff;

  &:hover {
    background-color: #623bff !important;
    color: #fff;
  }

  svg {
    font-size: 1.3rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    border-radius: 10px !important;
  }
}

._section-wrapper_55i6gj {
  padding: 1.75rem 1.5rem 3rem 1.5rem !important;
  margin-top: 1.5rem !important;
  // border: 1px solid #623bff !important;
  box-shadow: 0px 0px 3px #623bff, inset 0px 2px 10px #fff;
  border-radius: 0.375rem;
}

._fixed-mobile-table_55i6gj {
  height: 350px;
  overflow-x: scroll;
}

/* styles for front/_pods/main/payment/styles.scss */
._subscription-badge_11nstl {
  background-color: #fecaca;
  color: #991b1b;
}

._alert_11nstl {
  // position: absolute;
  // left: 0;
  // right: 0;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: -20px;
  margin-right: -20px;
}

._form_11nstl {
  padding: 30px 0 20px 0;
}

/* styles for front/_pods/main/profile/styles.scss */
._tabs_4ehava {
  display: flex;
  align-items: center;
  overflow-x: scroll;

  button {
    width: 200px;
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }
}

/* styles for front/_pods/main/subscriptions/styles.scss */
._view-selector_wq3r99 {
  width: 100%;

  @media (min-width: 992px) {
    width: 300px;
    flex: none;
  }
}

._subscription-card_wq3r99 {
  width: 100%;

  @media (min-width: 991px) {
    width: 370px !important;
  }

  @media (max-width: 991px) {
    width: 300px !important;
  }

  @media (max-width: 768px) {
    width: 100% !important;
  }
}

._grid_wq3r99 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.3rem;

  @media (max-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* styles for front/_pods/privacy-policy/styles.scss */


._header-container_g41vj6 {
  // background-color: #c3c3c3;
  ._title_g41vj6 {
    width: 100%;
    height: 250px;
  }
}

/* styles for front/_pods/register/styles.scss */
._card-width_idi4hs {
  width: 520px;
  @media (max-width: 768px) {
    width: 100%;
  }
}

/* styles for front/_pods/reset-password/styles.scss */
._form_ddnwem {
  width: 100%;

  @media (min-width: 767px) {
    width: 600px;
  }
}

/* styles for front/_pods/search/load/styles.scss */
._input-section_7hbxny {
  width: 100%;
  height: auto;
  display: flex;
  row-gap: 2rem;
  column-gap: 30px;
  align-items: end;
  flex-direction: row;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
}

._filters_7hbxny {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }
}
._data-card_7hbxny {
  border-radius: 24px !important;

  ._data-item_7hbxny {
    width: 33%;
  }

  ._path-line_7hbxny {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    background: repeating-linear-gradient(
        90deg,
        #6b46c1,
        #6b46c1 5px,
        transparent 5px,
        transparent 10px
    );
    opacity: 0.3;
  }

  ._restart-btn_7hbxny {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: red;
    color: white;
    font-size: 10px;
    cursor: pointer;
  }
}

._arrow-btn_7hbxny {
  font-size: 20px;
}

._switch-btn_7hbxny {
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 50% !important;
  width: 37px;
  height: 37px;
  color: #4154f1 !important;
  z-index: 2;
  background: white;
  border: 1px solid #CDD4DA !important;;

  &:hover {
    background: white;
  }

  &:disabled {
    background: white;
    color: #CDD4DA !important;
    opacity: 1;
  }
}

._input-label_7hbxny {
  color: white;
  margin-bottom: 4px;
}

._select-component_7hbxny {
  width: 100%;

  @media (min-width: 1200px) {
    &._fixed-width_7hbxny {
      width: 300px;
      flex: none;

      ._date-selector_7hbxny {
        display: none;
      }
    }
  }

  @media (max-width: 1200px) {
    &._fixed-width_7hbxny {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;
    }
  }

  @media (min-width: 768px) {
    &._date-selector_7hbxny {
      width: 225px;
    }
    &._multiple-selector_7hbxny {
      width: 300px;
    }
  }
}

._switch-component_7hbxny {
  width: 300px;
}

._search-component_7hbxny {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;

  div {
    flex: 1;
  }
}

._lg-btn_7hbxny {
  padding: 0.40rem 1rem !important;
  @media (max-width: 768px) {
    padding: 0.65rem 1rem !important;
  }
}

._negative-margin_7hbxny {
  @media (max-width: 576px) {
    margin-right: -12px;
    margin-left: -12px;
  }
}

._count-wrapper_7hbxny {
  background-color: #fff;
  box-shadow: 0px 0px 2px #623bff, inset 0px 2px 10px #fff;

  @media (min-width: 577px) {
    border-radius: 10px;
  }
}

._custom-spacing_7hbxny {
  padding: 0.35rem 0.75rem !important;
}

._other-filters-wrapper_7hbxny {
  display: flex;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;

  @media (max-width: 1200px) {
    margin-top: 22px;
    margin-bottom: 22px;
  }

  @media (max-width: 992px) {
    display: none !important;
  }
}

/* styles for front/_pods/search/styles.scss */
._search-section_1z0m7y {
  width: 100%;
  //min-height: 40vh;
  height: auto;
  //margin-top: 60px;

  //@media (min-width: 768px) {
  //  min-height: 250px;
  //}
}

/* styles for front/_pods/search/vehicle/styles.scss */
._input-section_g9dn8w {
  width: 100%;
  height: auto;
  display: flex;
  row-gap: 2rem;
  column-gap: 30px;
  align-items: end;
  flex-direction: row;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
}

._input-label_g9dn8w {
  color: white;
  margin-bottom: 4px;
}

._filters_g9dn8w {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }
}

._select-component_g9dn8w {
  width: 100%;

  @media (min-width: 1200px) {
    &._fixed-width_g9dn8w {
      width: 300px;
      flex: none;

      ._date-selector_g9dn8w {
        display: none;
      }
    }

    &._fixed-width-2_g9dn8w {
      width: 348px;
      flex: none;
    }
  }

  @media (max-width: 1200px) {
    &._fixed-width_g9dn8w {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }
  }

  @media (min-width: 768px) {
    &._date-selector_g9dn8w {
      width: 225px;
    }
    &._multiple-selector_g9dn8w {
      width: 300px;
    }
  }
}

._other-filters-wrapper_g9dn8w {
  display: flex;
  width: 100%;
  // margin-top: 16px;

  @media (max-width: 1200px) {
    // margin-top: 22px;
  }

  @media (max-width: 992px) {
    display: none !important;
  }
}

._arrow-btn_g9dn8w {
  font-size: 24px;
}

._negative-margin_g9dn8w {
  @media (max-width: 576px) {
    margin-right: -12px;
    margin-left: -12px;
  }
}

._count-wrapper_g9dn8w {
  background-color: #fff;
  box-shadow: 0px 0px 2px #623bff, inset 0px 2px 10px #fff;

  @media (min-width: 577px) {
    border-radius: 10px;
  }
}

._custom-spacing_g9dn8w {
  padding: 0.35rem 0.75rem !important;
}

._switch-component_g9dn8w {
  width: 300px;
}

/* styles for front/styles/app.scss */
// $enable-negative-margins: true;
@import "ember-bootstrap/bootstrap-utilities";

//Generate utilites for Cursor
$utilities: map-merge(
  $utilities,
  (
    "cursor": (
      property: cursor,
      values: auto default pointer wait text move help not-allowed,
    ),
  )
);

$primary: #623bff; // Replace with your new primary color
$nav-link-color: #623bff;
$nav-pills-link-active-bg: #623bff;
$btn-bg: #623bff;
$btn-primary-bg: #623bff;
$btn-primary-border-color: #623bff;

@import "ember-bootstrap/functions";
@import "ember-bootstrap/variables";
// @import "ember-bootstrap/variables-dark";
@import "ember-bootstrap/maps";
@import "ember-bootstrap/mixins";
@import "ember-bootstrap/utilities";
@import "ember-bootstrap/root";
@import "ember-bootstrap/reboot";
// @import "ember-bootstrap/type";
// @import "ember-bootstrap/images";
@import "ember-bootstrap/containers";
@import "ember-bootstrap/grid";
@import "ember-bootstrap/forms";
@import "ember-bootstrap/buttons";
@import "ember-bootstrap/transitions";
@import "ember-bootstrap/dropdown";
@import "ember-bootstrap/button-group";
@import "ember-bootstrap/nav";
@import "ember-bootstrap/navbar";
@import "ember-bootstrap/accordion";
@import "ember-bootstrap/badge";
@import "ember-bootstrap/list-group";
@import "ember-bootstrap/close";
@import "ember-bootstrap/modal";
@import "ember-bootstrap/popover";
@import "ember-bootstrap/helpers";
@import "ember-bootstrap/pagination";
@import "ember-bootstrap/breadcrumb";
@import "ember-bootstrap/utilities/api";

// @import "node_modules/bootstrap/scss/bootstrap";
//@import "node_modules/intl-tel-input/build/css/intlTelInput";

// @import 'ember-promise-modals';

@import './global';
@import './epm-overrides';
@import './spinner';
@import './table';
@import './theme';
@import './range-slider';
@import 'nouislider';
@import "ember-basic-dropdown";
@import "./scss/variables.scss"; // power-select styles
@import "./scss/base.scss"; // power-select styles

/* styles for front/styles/epm-overrides.scss */
._epm-backdrop_h54n0j,
._epm-modal-container_h54n0j,
._epm-modal_h54n0j {
  z-index: 100;
}

._ember-application_h54n0j {
  --epm-backdrop-background: rgba(153, 152, 150, 0.7);

  /*
   * Fixes for Percy not working properly with just "animation-fill-mode: forwards".
   * This will work fine as long as we do not set a delay for the backdrop out animation.
  */
  ._epm-backdrop_h54n0j,
  ._epm-modal_h54n0j._epm-fade_h54n0j {
    opacity: 1;
  }

  ._epm-backdrop_h54n0j._epm-out_h54n0j,
  ._epm-modal_h54n0j._epm-fade_h54n0j._epm-out_h54n0j {
    opacity: 0;
  }
}

._ember-application_h54n0j:not(#_ember-testing_h54n0j) {
  /* specified here because we can only have a single out duration for the backdrop */
  --epm-animation-backdrop-out-delay: 0s;
  --epm-animation-backdrop-out-duration: 600ms;

  &._has-popup-modal_h54n0j {
    --epm-animation-backdrop-in-duration: 120ms;
    --epm-animation-modal-in-delay: 0ms;
    --epm-animation-modal-in-duration: 170ms;
    --epm-animation-modal-out-duration: 50ms;
  }

  &._has-fullscreen-modal_h54n0j {
    --epm-animation-backdrop-in-duration: 600ms;
    --epm-animation-modal-in-duration: 250ms;
    --epm-animation-modal-out-duration: 250ms;
  }

  @media (prefers-reduced-motion: reduce) {
    --epm-animation-backdrop-in-duration: 0s !important;
    --epm-animation-backdrop-out-duration: 0s !important;
    --epm-animation-modal-in-duration: 0s !important;
    --epm-animation-modal-out-duration: 0s !important;
    --epm-animation-backdrop-in-delay: 0s !important;
    --epm-animation-backdrop-out-delay: 0s !important;
    --epm-animation-modal-in-delay: 0s !important;
    --epm-animation-modal-out-delay: 0s !important;
  }
}

._epm-modal_h54n0j._epm-fade_h54n0j {
  opacity: 0;
  transform: unset;
  animation: var(--epm-animation-modal-in-duration) step-end var(--epm-animation-modal-in-delay)
    forwards _fadein_h54n0j;
}

._epm-modal_h54n0j._epm-fade_h54n0j._epm-out_h54n0j {
  opacity: 1;
  transform: unset;
  animation: var(--epm-animation-modal-out-duration) ease-out var(--epm-animation-modal-out-delay)
    forwards _fade-out_h54n0j; // NOTE: the animation name needs to end with "-out" to work correctly
}

._epm-modal_h54n0j._epm-from-bottom_h54n0j {
  opacity: 1;
  transform: translate(0, 0);
  transform-origin: top center;
  animation: _from-bottom-in_h54n0j var(--epm-animation-modal-in-duration) ease-out;
}

._epm-modal_h54n0j._epm-from-bottom_h54n0j._epm-out_h54n0j {
  opacity: 0;
  transform: translate(0, calc(50vh + 50%));
  animation: _from-bottom-out_h54n0j var(--epm-animation-modal-out-duration) ease-in; // NOTE: the animation name needs to end with "-out" to work correctly
}

@keyframes _from-bottom-in_h54n0j {
  0% {
    opacity: 0;
    transform: translate(0, calc(50vh + 50%));
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes _from-bottom-out_h54n0j {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }

  100% {
    opacity: 0;
    transform: translate(0, calc(50vh + 50%));
  }
}

/* styles for front/styles/global.scss */
html, body {
  height: 100%;
}

._main-wrapper_y397ju {
  height: 100%;
  overflow: auto;
  scroll-behavior: smooth;
  background: url("../img/about-bg.jpg") center top no-repeat fixed;
  background-size: cover;
  position: relative;

  &._top-offset_y397ju {
    display: block;
    // height: calc(100% - 6rem);
    height: 100%;
    padding-top: 6rem;

    ._offset-div_y397ju {
      position: absolute;
      top: 0;
      display: block;
      background-color: #9f9ccc;
      // background-color: #111;
      height: 6rem;
      width: 100%;
      z-index: 10;
    }
  }

  &:before {
    content: '';
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.92);
    z-index: 9;
  }
}

._main-section_y397ju {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  z-index: 10;
}

._ember-power-select-options_y397ju {
  font-size: 15px;
}

._ember-power-select-selected-item_y397ju {
  width: 100%;

  ._selector-data_y397ju {
    position: absolute;
    right: 53px;
  }
}

._ember-power-select-trigger_y397ju[aria-disabled=true] {
  background-color: #e9ecef !important;
}

._power-icon_y397ju {
  font-size: 10px;
}

html._lock-scroll_y397ju {
  overflow: hidden;
}

._x-toggle-container_y397ju._small_y397ju {
  padding: 0 0.25rem 0 0;
}

._x-toggle_y397ju:checked + label > ._x-toggle-light_y397ju._x-toggle-btn_y397ju {
  background-color: #2b87db;
}

._ember-power-select-trigger-multiple-input_y397ju {
  margin-left: 8px;
}

._ember-power-select-selected-item_y397ju > div {
  width: calc(100% - 8px) !important;
}

._ember-power-select-option_y397ju span {
  display: block;
}

@media (max-width: 768px) {
  ._ember-power-select-option_y397ju {
    padding: 3px 12px !important;
  }
}

._dropdown-item-sm_y397ju {
  li._ember-power-select-option_y397ju {
    font-size: 0.95rem;
    font-weight: 500;
  }
}

._form-control_y397ju:focus {
  border-color: #623bff !important;
  box-shadow: none !important;
}

._ember-power-select-clear-btn_y397ju {
  font-size: 1.5rem !important;
  line-height: 1.5 !important;
}

._truncate_y397ju {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

._ember-cli-notifications-notification__container_y397ju ._c-notification__svg_y397ju {
  vertical-align: middle !important;
}

._ember-cli-notifications-notification__container_y397ju._ember-cli-notifications-notification__container--top-right_y397ju {
  margin-top: 6rem;
}

._field-error_y397ju {
  font-size: 14px;
  line-height: 0.75rem;
  margin: 0;
  margin-top: 3px;
  margin-right: 1px;
  margin-left: 2px;
  padding: 0;
  color: red;
  bottom: -20px;
}

._input-error_y397ju {
  color: red !important;

  input {
    border-color: red !important;

    &::placeholder {
      color: red !important;
    }
  }
}

._label-sm_y397ju {
  font-size: 14px;
  margin-left: 4px;
}

._remove-top-space_y397ju {
  margin-top: 0 !important;
}

._flex-none_y397ju {
  flex: none;
}

@media (max-width: 768px) {
  ._remove-top-space_y397ju {
    padding: 0 !important;
  }
}

@media (max-width: 768px) {
  ._ember-power-select-selected-item_y397ju,
  ._ember-power-select-placeholder_y397ju {
    margin-left: 16px !important;
    padding: 0.42rem 0;
  }

  ._ember-power-select-multiple-options_y397ju {
    padding: 0.42rem 0 !important;
    padding-left: 8px !important;
  }

  ._ember-power-select-status-icon_y397ju {
    right: 10px !important;
  }

  ._ember-power-select-trigger_y397ju {
    border-radius: 0.7rem !important;
  }

  ._ember-power-select-trigger_y397ju:focus, ._ember-power-select-trigger--active_y397ju {
    border-top: 1px solid #827be0 !important;
    border-bottom: 1px solid #827be0 !important;
    border-right: 1px solid #827be0 !important;
    border-left: 1px solid #827be0 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 111, 233, 0.60) !important;
  }

  ._ember-power-select-search-input_y397ju:focus {
    border-color: #827be0 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 111, 233, 0.60) !important;
  }
}

._scrollable-x_y397ju {
  overflow-x: auto; /* Enable horizontal scrolling if items overflow */
  white-space: nowrap; /* Keep items in a single line */
  align-items: center; /* Vertically align items */
  -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
  scrollbar-width: none; /* Hide scrollbar in Firefox */

  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar in Chrome, Safari, and Edge */
  }

  ._nav-tabs_y397ju {
    display: inline-flex;
    width: max-content; /* Ensures only the content width is used */
  }
}

._ember-power-select-multiple-options_y397ju ._ember-power-select-multiple-option_y397ju {
  margin-right: 5px; /* Adjust spacing between items */
}

._ember-power-select-multiple-options_y397ju ._ember-power-select-trigger_y397ju {
  flex-shrink: 0; /* Prevent shrinking of the trigger */
}

._latest-ads-type-selector_y397ju {
  ._type-toggle_y397ju {
    color: #623bff;
  }
  &:hover ._type-toggle_y397ju {
    color: #fff;
  }
}

$ember-power-select-default-border-radius: 6px !default;
$ember-power-select-line-height: 2.3 !default;
$ember-power-select-border-color: #ced4da !default;
$ember-power-select-trigger-icon-color: #999 !default;
$ember-power-select-focus-outline: 0 !default;
$ember-power-select-opened-border-radius: 0.3125rem !default;
$ember-power-select-search-input-border-radius: 3px !default;
$ember-power-select-dropdown-margin: 3px !default;
$ember-power-select-dropdown-box-shadow: rgba(black, 0.172549) 0px 6px 12px 0px !default;
$ember-power-select-highlighted-color: inherit !default;
$ember-power-select-highlighted-background: #f5f5f5 !default;
$ember-power-select-selected-background: #f5f5f5 !default;
$ember-power-select-focus-border-color: #66afe9 !default;
$ember-power-select-focus-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
0 0 8px rgba(102, 175, 233, 0.6) !default;
$ember-power-select-dropdown-border: 0.0625rem solid $ember-power-select-border-color !default;
$ember-power-select-dropdown-contiguous-border: $ember-power-select-dropdown-border !default;

/* styles for front/styles/range-slider.scss */
._noUi-connect_1ia0m6 {
  background-color: #2b87db;
}

._noUi-connects_1ia0m6 {
  background-color: #ccc;
}

._noUi-horizontal_1ia0m6 ._noUi-handle_1ia0m6 {
  border-color: #2b87db;
  border-radius: 50px;
  width: 24px;
  height: 24px;
  top: -10px;
  &:before, &:after, {
    display: none;
  }
}

._noUi-handle_1ia0m6._noUi-handle-lower_1ia0m6 {
  right: -13px;
}

._noUi-handle_1ia0m6._noUi-handle-upper_1ia0m6 {
  right: -11px;
}

._noUi-target_1ia0m6 {
  border: none;
}

._noUi-horizontal_1ia0m6 {
  height: 6px;
}

/* styles for front/styles/spinner.scss */
._spinner-container_o09rf0 {
  width: 100%;
  height: 100dvh;
  position: absolute;
  z-index: 1200;
  background-color: rgba(white, .4);

  background-image:
    radial-gradient(
        #0c63e4 30%,
        hsla(0,40%,100%,0) 35%
    ),
    radial-gradient(
        #0c63e4 30%,
        hsla(0,40%,100%,0) 35%
    ),
    radial-gradient(
        #0c63e4 30%,
        hsla(0,40%,100%,0) 35%
    ),
    radial-gradient(
        #0c63e4 30%,
        hsla(0,40%,100%,0) 35%
    ),
    radial-gradient(
        #0c63e4 30%,
        hsla(0,40%,100%,0) 35%
    ),
    radial-gradient(
        #0c63e4 30%,
        hsla(0,40%,100%,0) 35%
    ),
    radial-gradient(
        #0c63e4 30%,
        hsla(0,40%,100%,0) 35%
    ),
    radial-gradient(
        #0c63e4 30%,
        hsla(0,40%,100%,0) 35%
    );

  background-position:
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%;
  background-size: 1.5em 1.5em;
  background-repeat: no-repeat;

  animation: _bloom_o09rf0 2.5s infinite;
}

@keyframes _bloom_o09rf0 {
  6.25% {
    background-position:
      50% calc(50% - 1.5em),
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%;
  }
  12.5% {
    background-position:
      50% calc(50% - 1.5em),
      calc(50% + 1em) calc(50% - 1em),
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%;
  }
  18.75% {
    background-position:
      50% calc(50% - 1.5em),
      calc(50% + 1em) calc(50% - 1em),
      calc(50% + 1.5em) 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%;
  }
  25% {
    background-position:
      50% calc(50% - 1.5em),
      calc(50% + 1em) calc(50% - 1em),
      calc(50% + 1.5em) 50%,
      calc(50% + 1em) calc(50% + 1em),
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%;
  }
  31.25% {
    background-position:
      50% calc(50% - 1.5em),
      calc(50% + 1em) calc(50% - 1em),
      calc(50% + 1.5em) 50%,
      calc(50% + 1em) calc(50% + 1em),
      50% calc(50% + 1.5em),
      50% 50%,
      50% 50%,
      50% 50%;
  }
  37.5% {
    background-position:
      50% calc(50% - 1.5em),
      calc(50% + 1em) calc(50% - 1em),
      calc(50% + 1.5em) 50%,
      calc(50% + 1em) calc(50% + 1em),
      50% calc(50% + 1.5em),
      calc(50% - 1em) calc(50% + 1em),
      50% 50%,
      50% 50%;
  }
  43.75% {
    background-position:
      50% calc(50% - 1.5em),
      calc(50% + 1em) calc(50% - 1em),
      calc(50% + 1.5em) 50%,
      calc(50% + 1em) calc(50% + 1em),
      50% calc(50% + 1.5em),
      calc(50% - 1em) calc(50% + 1em),
      calc(50% - 1.5em) 50%,
      50% 50%;
  }
  50% {
    background-position:
      50% 50%,
      calc(50% + 1em) calc(50% - 1em),
      calc(50% + 1.5em) 50%,
      calc(50% + 1em) calc(50% + 1em),
      50% calc(50% + 1.5em),
      calc(50% - 1em) calc(50% + 1em),
      calc(50% - 1.5em) 50%,
      calc(50% - 1em) calc(50% - 1em);
  }
  56.25% {
    background-position:
      50% 50%,
      50% 50%,
      calc(50% + 1.5em) 50%,
      calc(50% + 1em) calc(50% + 1em),
      50% calc(50% + 1.5em),
      calc(50% - 1em) calc(50% + 1em),
      calc(50% - 1.5em) 50%,
      calc(50% - 1em) calc(50% - 1em);
  }
  62.5% {
    background-position:
      50% 50%,
      50% 50%,
      50% 50%,
      calc(50% + 1em) calc(50% + 1em),
      50% calc(50% + 1.5em),
      calc(50% - 1em) calc(50% + 1em),
      calc(50% - 1.5em) 50%,
      calc(50% - 1em) calc(50% - 1em);
  }
  68.75% {
    background-position:
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% calc(50% + 1.5em),
      calc(50% - 1em) calc(50% + 1em),
      calc(50% - 1.5em) 50%,
      calc(50% - 1em) calc(50% - 1em);
  }
  75% {
    background-position:
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      calc(50% - 1em) calc(50% + 1em),
      calc(50% - 1.5em) 50%,
      calc(50% - 1em) calc(50% - 1em);
  }
  81.25% {
    background-position:
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      calc(50% - 1.5em) 50%,
      calc(50% - 1em) calc(50% - 1em);
  }
  87.5% {
    background-position:
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      calc(50% - 1em) calc(50% - 1em);
  }
}

/* styles for front/styles/table.scss */
$table-header-background-color: #f8f8f8;
$table-border-color: #dcdcdc;
$table-hover-color: #e5edf8;

._ember-table_1lw761 {
  //border: solid 1px #dddddd;

  ._ember-table-overflow_1lw761 {
    overflow: visible;
  }

  table {
    width: 100%;
  }

  th,
  td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 16px;
    padding: 4px 8px;
  }

  tbody {
    td {
      border-top: none;
      border-left: none;
      border-bottom: $table-border-color 1px solid;
      //border-right: $table-border-color 1px solid;
      background-color: #ffffff;
      padding: 4px 8px;

      ._et-toggle-select_1lw761 {
        // display: none;
        padding: 4px;
        display: flex;
        align-items: center;
        margin: 0;
        appearance: none;
        position: relative;
        cursor: pointer;
      }

      ._et-cell-container_1lw761 {
        display: flex;
      }

      ._et-cell-content_1lw761 {
        flex: 1;
        padding: 4px;
      }

      &:last-child {
        border-right: none;
      }

      &._is-fixed-right_1lw761 {
        border-left: solid 1px $table-border-color;
      }
    }
  }

  th,
  tfoot td {
    // padding: 3px 15px;
    background-color: $table-header-background-color;
    // font-family: 'Univers LT W01 65 Bold';
    line-height: 1.4;
    font-weight: bold;
    text-align: center;
    box-sizing: border-box;
    &:first-child {
      padding: 3px 11px;
    }
  }

  tfoot td {
    border-top: 1px solid $table-border-color;
    border-right: solid 1px $table-border-color;

    &:last-child {
      border-right: none;
    }
  }

  thead th {
    background-color: #fff;
    border-bottom: 1px solid $table-border-color;
    //border-right: solid 1px $table-border-color;
    //box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;

    //&:last-child {
    //  border-right: none;
    //}

    &._is-fixed-right_1lw761 {
      border-left: solid 1px $table-border-color;

      ._et-header-resize-area_1lw761 {
        left: 0;
      }
    }
  }

  tr:hover {
    th {
      cursor: pointer;
    }

    td {
      background-color: #efefef;
    }
  }

  tr._is-selected_1lw761 {
    td,
    th {
      background-color: #efefef;
    }
  }
}

._custom-table-w_1lw761 table {
  width: 100%;
  max-width: 1200px;
}
