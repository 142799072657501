$table-header-background-color: #f8f8f8;
$table-border-color: #dcdcdc;
$table-hover-color: #e5edf8;

.ember-table {
  //border: solid 1px #dddddd;

  .ember-table-overflow {
    overflow: visible;
  }

  table {
    width: 100%;
  }

  th,
  td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 16px;
    padding: 4px 8px;
  }

  tbody {
    td {
      border-top: none;
      border-left: none;
      border-bottom: $table-border-color 1px solid;
      //border-right: $table-border-color 1px solid;
      background-color: #ffffff;
      padding: 4px 8px;

      .et-toggle-select {
        // display: none;
        padding: 4px;
        display: flex;
        align-items: center;
        margin: 0;
        appearance: none;
        position: relative;
        cursor: pointer;
      }

      .et-cell-container {
        display: flex;
      }

      .et-cell-content {
        flex: 1;
        padding: 4px;
      }

      &:last-child {
        border-right: none;
      }

      &.is-fixed-right {
        border-left: solid 1px $table-border-color;
      }
    }
  }

  th,
  tfoot td {
    // padding: 3px 15px;
    background-color: $table-header-background-color;
    // font-family: 'Univers LT W01 65 Bold';
    line-height: 1.4;
    font-weight: bold;
    text-align: center;
    box-sizing: border-box;
    &:first-child {
      padding: 3px 11px;
    }
  }

  tfoot td {
    border-top: 1px solid $table-border-color;
    border-right: solid 1px $table-border-color;

    &:last-child {
      border-right: none;
    }
  }

  thead th {
    background-color: #fff;
    border-bottom: 1px solid $table-border-color;
    //border-right: solid 1px $table-border-color;
    //box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;

    //&:last-child {
    //  border-right: none;
    //}

    &.is-fixed-right {
      border-left: solid 1px $table-border-color;

      .et-header-resize-area {
        left: 0;
      }
    }
  }

  tr:hover {
    th {
      cursor: pointer;
    }

    td {
      background-color: #efefef;
    }
  }

  tr.is-selected {
    td,
    th {
      background-color: #efefef;
    }
  }
}

.custom-table-w table {
  width: 100%;
  max-width: 1200px;
}
