/**
* Template Name: YUKON
* Template URL: https://bootstrapmade.com/nice-admin-bootstrap-admin-html-template/
* Updated: Apr 20 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap&subset=cyrillic,latin');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap&subset=cyrillic,latin');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap&subset=cyrillic,latin');

:root {
  scroll-behavior: smooth;
}

.flex-auto {
  flex: auto;
}

.flex-1 {
  flex: 1;
}

body {
  font-family: 'Open Sans', sans-serif;
  background: #f6f9ff;
  color: #444444;
}

a {
  color: #4154f1;
  text-decoration: none;
}

a:hover {
  color: #717ff5;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Nunito', sans-serif;
}

/*--------------------------------------------------------------
# Main
--------------------------------------------------------------*/
/*@media (max-width: 1199px) {
  #main {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}*/

.px-px {
  padding-left: 1px;
  padding-right: 1px;
}

.px-9px {
  padding-left: 9px;
  padding-right: 9px;
}

.pb-px {
  padding-bottom: 1px;
}

#main {
  padding-top: 60px;
  transition: all 0.3s;
  font-weight: 500;
  font-family: 'Nunito', sans-serif;
  flex: 1;
}

/*--------------------------------------------------------------
# Page Title
--------------------------------------------------------------*/
.pagetitle {
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.pagetitle h1 {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 600;
  color: #012970;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
/*.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background: #4154f1;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top svg {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #6776f4;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}*/

/*--------------------------------------------------------------
# Override some default Bootstrap stylings
--------------------------------------------------------------*/
/* Dropdown menus */
.dropdown-menu {
  border-radius: 4px;
  padding: 10px 0;
  /*animation-name: dropdown-animate;*/
  /*animation-duration: 0.2s;*/
  /*animation-fill-mode: both;*/
  border: 0;
  box-shadow: 0 5px 30px 0 rgba(82, 63, 105, 0.2);
}

.dropdown-menu .dropdown-header,
.dropdown-menu .dropdown-footer {
  text-align: center;
  font-size: 15px;
  padding: 10px 25px;
}

.dropdown-menu .dropdown-footer a {
  color: #444444;
  text-decoration: underline;
}

.dropdown-menu .dropdown-footer a:hover {
  text-decoration: none;
}

.dropdown-menu .dropdown-divider {
  color: #a5c5fe;
  margin: 0;
}

.dropdown-menu .dropdown-item {
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
}

.dropdown-menu .dropdown-item:not(.active) svg {
  color: #686868;
}

.dropdown-menu .dropdown-item svg {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
  transition: 0.1s;
  width: 18px;
}

.dropdown-menu .dropdown-item.active {
  background-color: #6f92d5;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #f6f9ff;
  color: #686868;
}

.dropdown-menu .dropdown-item:hover svg {
  color: #686868;
}

@media (min-width: 768px) {
  .dropdown-menu-arrow::before {
    content: '';
    width: 13px;
    height: 13px;
    background: #fff;
    position: absolute;
    top: -7px;
    right: 20px;
    transform: rotate(45deg);
    border-top: 1px solid #eaedf1;
    border-left: 1px solid #eaedf1;
  }
}

@keyframes dropdown-animate {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

/* Light Backgrounds */

.bg-primary {
  background-color: #4154f1 !important;
}

.bg-primary-light {
  background-color: #cfe2ff;
  border-color: #cfe2ff;
}

.bg-secondary-light {
  background-color: #e2e3e5;
  border-color: #e2e3e5;
}

.bg-success-light {
  background-color: #d1e7dd;
  border-color: #d1e7dd;
}

.bg-danger-light {
  background-color: #f8d7da;
  border-color: #f8d7da;
}

.bg-warning-light {
  background-color: #fff3cd;
  border-color: #fff3cd;
}

.bg-info-light {
  background-color: #cff4fc;
  border-color: #cff4fc;
}

.bg-dark-light {
  background-color: #d3d3d4;
  border-color: #d3d3d4;
}

/* Card */
.card {
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
  /*background-color: #fbfafb;*/
  background-color: #fff;
}

.card-header,
.card-footer {
  border-color: #ebeef4;
  background-color: #fff;
  color: #798eb3;
  padding: 15px;
}

.card-title {
  padding: 20px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  color: #012970;
  font-family: 'Poppins', sans-serif;
}

.card-title span {
  color: #899bbd;
  font-size: 14px;
  font-weight: 400;
}

.card-body {
  padding: 0 20px 20px 20px;
}

.card-img-overlay {
  background-color: rgba(255, 255, 255, 0.6);
}

.card.mobile-view .card-title {
  padding-left: 11px;
  padding-right: 11px;
}

.card.mobile-view {
  border-radius: unset;
}

.card.mobile-view .card-body {
  padding: 0 8px 20px 8px;
}

/* Alerts */
.alert-heading {
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
}

/* Close Button */
.btn-close {
  background-size: 25%;
}

.btn-close:focus {
  outline: 0;
  box-shadow: none;
}

/* Accordion */
.accordion-item {
  border: 1px solid #ebeef4;
}

.accordion-button:focus {
  outline: 0;
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: #012970;
  background-color: #f6f9ff;
}

/*.accordion-flush .accordion-button {
  padding: 15px 0;
  background: none;
  border: 0;
}

.accordion-flush .accordion-button:not(.collapsed) {
  box-shadow: none;
  color: #4154f1;
}

.accordion-flush .accordion-body {
  padding: 0 0 15px 0;
  color: #3e4f6f;
  font-size: 15px;
}*/

/* Breadcrumbs */
.breadcrumb {
  font-size: 14px;
  font-family: 'Nunito', sans-serif;
  color: #899bbd;
  font-weight: 600;
}

.breadcrumb a {
  color: #899bbd;
  transition: 0.3s;
}

.breadcrumb a:hover {
  color: #51678f;
}

.breadcrumb .breadcrumb-item::before {
  color: #899bbd;
}

.breadcrumb .is-active {
  color: #51678f;
  font-weight: 600;
}

/* Bordered Tabs */
.nav-tabs-bordered {
  border-bottom: 2px solid #ebeef4;
}

.nav-tabs-bordered .nav-link {
  margin-bottom: -2px;
  border: none;
  color: #2c384e;
}

.nav-tabs-bordered .nav-link:hover,
.nav-tabs-bordered .nav-link:focus {
  color: #4154f1;
}

.nav-tabs-bordered .nav-link.active {
  background-color: #fff;
  color: #4154f1;
  border-bottom: 2px solid #4154f1;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.logo {
  line-height: 1;
}

@media (min-width: 1200px) {
  .logo {
    width: 280px;
  }
}

.logo img {
  max-height: 26px;
  margin-right: 6px;
}

.logo span {
  font-size: 26px;
  font-weight: 700;
  color: #012970;
  font-family: 'Nunito', sans-serif;
}

.header {
  transition: all 0.5s;
  z-index: 997;
  height: 60px;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  background-color: #fff;
  padding-left: 20px;
  /* Toggle Sidebar Button */
  /* Search Bar */
}

.header .toggle-sidebar-btn {
  font-size: 32px;
  cursor: pointer;
  color: #012970;
}

.header .search-bar {
  min-width: 360px;
  padding: 0 20px;
}

@media (max-width: 1199px) {
  .header .search-bar {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    padding: 20px;
    box-shadow: 0px 0px 15px 0px rgba(1, 41, 112, 0.1);
    background: white;
    z-index: 9999;
    transition: 0.3s;
    visibility: hidden;
    opacity: 0;
  }

  .header .search-bar-show {
    top: 60px;
    visibility: visible;
    opacity: 1;
  }
}

.header .search-form {
  width: 100%;
}

.header .search-form input {
  border: 0;
  font-size: 14px;
  color: #012970;
  border: 1px solid rgba(1, 41, 112, 0.2);
  padding: 7px 38px 7px 8px;
  border-radius: 3px;
  transition: 0.3s;
  width: 100%;
}

.header .search-form input:focus,
.header .search-form input:hover {
  outline: none;
  box-shadow: 0 0 10px 0 rgba(1, 41, 112, 0.15);
  border: 1px solid rgba(1, 41, 112, 0.3);
}

.header .search-form button {
  border: 0;
  padding: 0;
  margin-left: -30px;
  background: none;
}

.header .search-form button svg {
  color: #012970;
}

/*--------------------------------------------------------------
# Header Nav
--------------------------------------------------------------*/
.header-nav ul {
  list-style: none;
}

.header-nav > ul {
  margin: 0;
  padding: 0;
}

.header-nav .nav-icon {
  font-size: 22px;
  color: #012970;
  margin-right: 25px;
  position: relative;
}

.header-nav .nav-profile {
  color: #012970;
}

.header-nav .nav-profile img {
  max-height: 36px;
}

.header-nav .nav-profile svg {
  font-size: 30px;
}

.header-nav .nav-profile span {
  font-size: 14px;
  font-weight: 600;
}

.header-nav .route-links .nav-link {
  font-size: 15px;
  font-weight: 500;
  color: #012970;
  transition: 0.3;
  transition: 0.3s;
}

.header-nav .route-links .nav-link:hover,
.header-nav .route-links .nav-link.active {
  color: #4154f1;
}

.header-nav .badge-number {
  position: absolute;
  inset: -2px -5px auto auto;
  font-weight: normal;
  font-size: 12px;
  padding: 3px 6px;
}

.header-nav .notifications {
  inset: 8px -15px auto auto !important;
}

.header-nav .notifications .notification-item {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  transition: 0.3s;
}

.header-nav .notifications .notification-item svg {
  margin: 0 20px 0 10px;
  font-size: 24px;
}

.header-nav .notifications .notification-item h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.header-nav .notifications .notification-item p {
  font-size: 13px;
  margin-bottom: 3px;
  color: #919191;
}

.header-nav .notifications .notification-item:hover {
  background-color: #f6f9ff;
}

.header-nav .messages {
  inset: 8px -15px auto auto !important;
}

.header-nav .messages .message-item {
  padding: 15px 10px;
  transition: 0.3s;
}

.header-nav .messages .message-item a {
  display: flex;
}

.header-nav .messages .message-item img {
  margin: 0 20px 0 10px;
  max-height: 40px;
}

.header-nav .messages .message-item h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #444444;
}

.header-nav .messages .message-item p {
  font-size: 13px;
  margin-bottom: 3px;
  color: #919191;
}

.header-nav .messages .message-item:hover {
  background-color: #f6f9ff;
}

.header-nav .profile {
  min-width: 240px;
  padding-bottom: 0;
  top: 8px !important;
}

.header-nav .my-adds {
  min-width: 160px;
  padding-bottom: 0;
  top: 8px !important;
  overflow: hidden;
  padding-top: 0 !important;
}

.header-nav .profile .dropdown-header h6 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
  color: #444444;
}

.header-nav .profile .dropdown-header span {
  font-size: 14px;
}

.header-nav .profile .dropdown-item {
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
}

.header-nav .profile .dropdown-item svg {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

.header-nav .profile .dropdown-item:hover {
  background-color: #f6f9ff;
}

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/
.sidebar {
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 300px;
  z-index: 996;
  transition: all 0.3s;
  padding: 20px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #aab7cf transparent;
  box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
  background-color: #fff;
}

.sidebar {
  left: -300px;
}

.sidebar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #fff;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #aab7cf;
}

@media (min-width: 1200px) {
  #main,
  #footer {
    /*margin-left: 300px;*/
  }
}

@media (max-width: 1199px) {
  .toggle-sidebar .sidebar {
    left: 0;
  }
}

@media (min-width: 1200px) {
  .toggle-sidebar #main,
  .toggle-sidebar #footer {
    margin-left: 0;
  }

  .toggle-sidebar .sidebar {
    left: -300px;
  }
}

.sidebar-nav {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-item {
  margin-bottom: 5px;
}

.sidebar-nav .nav-item .nav-icon {
  width: 20px;
}

.sidebar-nav .nav-heading {
  font-size: 11px;
  text-transform: uppercase;
  color: #899bbd;
  font-weight: 600;
  margin: 10px 0 5px 15px;
}

.sidebar-nav .nav-link.active-link {
  color: #4154f1;
  background: #f6f9ff;
}

.sidebar-nav .nav-link.active-link svg {
  color: #4154f1;
}

.sidebar-nav .nav-link {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: #012970;
  background: #fff;
  transition: 0.3;
  padding: 10px 15px;
  border-radius: 4px;
}

.sidebar-nav .nav-link svg {
  font-size: 16px;
  margin-right: 10px;
  color: #899bbd;
}

.sidebar-nav .nav-link:hover {
  color: #4154f1;
  background: #f6f9ff;
}

.sidebar-nav .nav-link:hover svg {
  color: #4154f1;
}

.sidebar-nav .nav-link .bi-chevron-down {
  margin-right: 0;
  transition: transform 0.2s ease-in-out;
}

.sidebar-nav .nav-link.active-link .bi-chevron-down {
  transform: rotate(180deg);
}

.sidebar-nav .nav-content {
  padding: 5px 0 0 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-content a {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #012970;
  transition: 0.3;
  padding: 10px 0 10px 40px;
  transition: 0.3s;
}

.sidebar-nav .nav-content a svg {
  font-size: 6px;
  margin-right: 8px;
  line-height: 0;
  border-radius: 50%;
}

.sidebar-nav .nav-content a:hover,
.sidebar-nav .nav-content a.active {
  color: #4154f1;
}

.sidebar-nav .nav-content a.active svg {
  background-color: #4154f1;
}

/*--------------------------------------------------------------
# Mini Sidebar
--------------------------------------------------------------*/
.mini-sidebar {
  position: fixed;
  top: 72%;
  right: -192px;
  width: 192px;
  z-index: 996;
  transition: all 0.3s;
  padding: 8px 12px;
  overflow: visible;
  scrollbar-width: thin;
  scrollbar-color: #aab7cf transparent;
  box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
  background-color: #fff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border: 2px solid #623bff;
  border-right: unset;
}

.mini-sidebar.toggled {
  right: 0;
}

.mini-sidebar-toggle {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: -16px;
  background-color: #623bff;
  padding: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: white;
}

.mini-sidebar-nav {
  display: flex;
  flex-direction: row;
}

.mini-sidebar-nav .nav-item .nav-icon {
  font-size: 1.5rem;
}

.mini-sidebar-nav .nav-link {
  border: 1px solid #dee2e6;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow:
    0px 0px 2px #aaa,
    inset 0px 2px 10px #fff;
}

/*--------------------------------------------------------------
# Profie Page
--------------------------------------------------------------*/
.profile .profile-card img {
  max-width: 120px;
}

.profile .profile-card svg.fa-circle-user {
  font-size: 120px;
  color: #2c384e;
}

.profile .profile-card h2 {
  font-size: 24px;
  font-weight: 700;
  color: #2c384e;
  margin: 10px 0 0 0;
}

.profile .profile-card h3 {
  font-size: 18px;
}

.profile .profile-card .social-links a {
  font-size: 20px;
  display: inline-block;
  color: rgba(1, 41, 112, 0.5);
  line-height: 0;
  margin-right: 10px;
  transition: 0.3s;
}

.profile .profile-card .social-links a:hover {
  color: #012970;
}

.ad .ad-overview .row,
.profile .profile-overview .row {
  margin-bottom: 20px;
  font-size: 15px;
}

.ad .ad-overview .card-title,
.profile .profile-overview .card-title {
  color: #012970;
}

.ad .ad-overview .label,
.profile .profile-overview .label {
  font-weight: 600;
  color: rgba(1, 41, 112, 0.6);
}

.profile .profile-edit label {
  font-weight: 600;
  color: rgba(1, 41, 112, 0.6);
}

.profile .profile-edit img {
  max-width: 120px;
}

.profile .profile-edit .fa-circle-user {
  font-size: 120px;
  color: #2c384e;
}

.ad .tab-pane,
.profile .tab-pane {
  display: block;
  padding: 0 1rem;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  padding: 14px 0;
  font-size: 14px;
  transition: all 0.3s;
  border-top: 1px solid #cddfff;
  background-color: #623bff;
}

.footer .copyright {
  text-align: center;
  /*color: #012970;*/
  color: #fff;
}

.footer .credits {
  padding-top: 5px;
  text-align: center;
  font-size: 13px;
  /*color: #012970;*/
  color: #fff;
}

/*--------------------------------------------------------------
# Pagination
--------------------------------------------------------------*/

.page-link.active,
.active > .page-link {
  background-color: #623bff;
  border-color: #623bff;
}

.page-link {
  color: #623bff;
}

/*--------------------------------------------------------------
# Buttons
--------------------------------------------------------------*/

.btn.btn-primary,
.btn.btn-danger,
.btn.btn-secondary {
  padding: 0.5rem 1rem;
  border-radius: 0.6rem;

  &.btn-icon-sm {
    @media (max-width: 768px) {
      padding: 0.75rem 1rem;
    }
  }
}

.btn.btn-primary:hover,
.btn.btn-danger:hover,
.btn.btn-secondary:hover {
  background-color: #7255e9;
  border-color: #7255e9;
}

.btn.btn-sm.btn-primary,
.btn.btn-sm.btn-danger,
.btn.btn-sm.btn-secondary {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn.btn-primary,
.btn-group > .btn-group:not(:first-child) > .btn.btn-primary {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn.btn-primary,
.btn-group > .btn-group:not(:first-child) > .btn.btn-primary {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-primary {
  --bs-btn-bg: #623bff;
  --bs-btn-border-color: #623bff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-disabled-bg: #623bff;
  --bs-btn-disabled-border-color: #623bff;
}

.btn-outline-primary {
  --bs-btn-color: #623bff;
  --bs-btn-border-color: #623bff;
  --bs-btn-hover-bg: #623bff;
  --bs-btn-hover-border-color: #623bff;
  --bs-btn-active-bg: #623bff;
  --bs-btn-active-border-color: #623bff;
  --bs-btn-disabled-color: #623bff;
  --bs-btn-disabled-border-color: #623bff;
}

/*--------------------------------------------------------------
# Edit Form
--------------------------------------------------------------*/

.form-row-label {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 0.9rem;
  color: #2b3c5e;
}

.form-wrapper {
  background-color: #f3f4f6;
  border: 1px solid #ccc;
  padding: 0.75rem 1rem;
  border-radius: 6px;
}

.back-icon-align {
  margin-right: 0.25rem;
  margin-bottom: -1px;
}

.form-bottom-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.07);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding-bottom: 25px !important;
}

.form-rounded-footer {
  border-radius: 0.375rem;
}

.record-state {
  margin: 0 -20px 1.5rem -20px;
  padding: 2px 1rem;
  /*background-color: #bdbbdb;*/
  background-color: #b5b2dc;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.input-reset-btn {
  padding-left: 0.65rem !important;
  padding-right: 0.65rem !important;
}

.input-btn {
  position: absolute;
  padding: 0 12px;
  color: #000000bd;
  cursor: pointer;
  font-size: 1.1rem;
}

.clear-btn,
.password-btn,
.loading-btn {
  right: 0;
  transition: all 0.14s ease-out;
  color: #999;
}

.password-btn {
  top: 50%;
  transform: translate(0, -50%);
}

.clear-btn:hover {
  transform: scale(1.15);
}

@media (max-width: 768px) {
  .form-wrapper {
    border-radius: 0.75rem;
  }

  .input-reset-btn {
    border-radius: 0.55rem !important;
  }
}

.text-primary-theme {
  color: #623bff;
}
