.spinner-container {
  width: 100%;
  height: 100dvh;
  position: absolute;
  z-index: 1200;
  background-color: rgba(white, .4);

  background-image:
    radial-gradient(
        #0c63e4 30%,
        hsla(0,40%,100%,0) 35%
    ),
    radial-gradient(
        #0c63e4 30%,
        hsla(0,40%,100%,0) 35%
    ),
    radial-gradient(
        #0c63e4 30%,
        hsla(0,40%,100%,0) 35%
    ),
    radial-gradient(
        #0c63e4 30%,
        hsla(0,40%,100%,0) 35%
    ),
    radial-gradient(
        #0c63e4 30%,
        hsla(0,40%,100%,0) 35%
    ),
    radial-gradient(
        #0c63e4 30%,
        hsla(0,40%,100%,0) 35%
    ),
    radial-gradient(
        #0c63e4 30%,
        hsla(0,40%,100%,0) 35%
    ),
    radial-gradient(
        #0c63e4 30%,
        hsla(0,40%,100%,0) 35%
    );

  background-position:
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%;
  background-size: 1.5em 1.5em;
  background-repeat: no-repeat;

  animation: bloom 2.5s infinite;
}

@keyframes bloom {
  6.25% {
    background-position:
      50% calc(50% - 1.5em),
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%;
  }
  12.5% {
    background-position:
      50% calc(50% - 1.5em),
      calc(50% + 1em) calc(50% - 1em),
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%;
  }
  18.75% {
    background-position:
      50% calc(50% - 1.5em),
      calc(50% + 1em) calc(50% - 1em),
      calc(50% + 1.5em) 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%;
  }
  25% {
    background-position:
      50% calc(50% - 1.5em),
      calc(50% + 1em) calc(50% - 1em),
      calc(50% + 1.5em) 50%,
      calc(50% + 1em) calc(50% + 1em),
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%;
  }
  31.25% {
    background-position:
      50% calc(50% - 1.5em),
      calc(50% + 1em) calc(50% - 1em),
      calc(50% + 1.5em) 50%,
      calc(50% + 1em) calc(50% + 1em),
      50% calc(50% + 1.5em),
      50% 50%,
      50% 50%,
      50% 50%;
  }
  37.5% {
    background-position:
      50% calc(50% - 1.5em),
      calc(50% + 1em) calc(50% - 1em),
      calc(50% + 1.5em) 50%,
      calc(50% + 1em) calc(50% + 1em),
      50% calc(50% + 1.5em),
      calc(50% - 1em) calc(50% + 1em),
      50% 50%,
      50% 50%;
  }
  43.75% {
    background-position:
      50% calc(50% - 1.5em),
      calc(50% + 1em) calc(50% - 1em),
      calc(50% + 1.5em) 50%,
      calc(50% + 1em) calc(50% + 1em),
      50% calc(50% + 1.5em),
      calc(50% - 1em) calc(50% + 1em),
      calc(50% - 1.5em) 50%,
      50% 50%;
  }
  50% {
    background-position:
      50% 50%,
      calc(50% + 1em) calc(50% - 1em),
      calc(50% + 1.5em) 50%,
      calc(50% + 1em) calc(50% + 1em),
      50% calc(50% + 1.5em),
      calc(50% - 1em) calc(50% + 1em),
      calc(50% - 1.5em) 50%,
      calc(50% - 1em) calc(50% - 1em);
  }
  56.25% {
    background-position:
      50% 50%,
      50% 50%,
      calc(50% + 1.5em) 50%,
      calc(50% + 1em) calc(50% + 1em),
      50% calc(50% + 1.5em),
      calc(50% - 1em) calc(50% + 1em),
      calc(50% - 1.5em) 50%,
      calc(50% - 1em) calc(50% - 1em);
  }
  62.5% {
    background-position:
      50% 50%,
      50% 50%,
      50% 50%,
      calc(50% + 1em) calc(50% + 1em),
      50% calc(50% + 1.5em),
      calc(50% - 1em) calc(50% + 1em),
      calc(50% - 1.5em) 50%,
      calc(50% - 1em) calc(50% - 1em);
  }
  68.75% {
    background-position:
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% calc(50% + 1.5em),
      calc(50% - 1em) calc(50% + 1em),
      calc(50% - 1.5em) 50%,
      calc(50% - 1em) calc(50% - 1em);
  }
  75% {
    background-position:
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      calc(50% - 1em) calc(50% + 1em),
      calc(50% - 1.5em) 50%,
      calc(50% - 1em) calc(50% - 1em);
  }
  81.25% {
    background-position:
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      calc(50% - 1.5em) 50%,
      calc(50% - 1em) calc(50% - 1em);
  }
  87.5% {
    background-position:
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      50% 50%,
      calc(50% - 1em) calc(50% - 1em);
  }
}
